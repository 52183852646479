
import axios from 'axios'
import {ref} from "vue"
import {useApi} from "@/composition/useApi.js"

export function useCoins() {

    const { api, params } = useApi()

    const coins = ref(null)

    const sendCoins = (body, user_id) => {
        return axios.post(api + `/api/v1/cp/users/${user_id}/bonuses/`, body, params)
    }
    const getCoins = (user_id) => {
        return axios.get(api + `/api/v1/cp/users/${user_id}/bonuses/`, params)
        .then((r) => {
            coins.value = r.data.data
        })
    }

    const changeCoins = (body, bonus_id) => {
        return axios.put(api + `/api/v1/cp/bonuses/${bonus_id}/`, body, params)
    }


    return {
        sendCoins,
        getCoins,
        changeCoins,
        coins,
    }
}