<template>
    <div class="header-profile">
        <div class="header-profile__wrapper">
            <div class="header-profile__logotype">
                <img src="@/assets/menu.svg" class="header-profile__logotype_menu-btn pointer" @click="showMenu">
                <img src="@/assets/logo.svg" class="header-profile__logotype_logo">
            </div>
            <div class="header-profile__admin pointer">
                <img v-if="!admin.avatar" src="@/assets/user.png" class="header-profile__admin_img" @click="profileSettings = !profileSettings">
                <img v-else :src="admin.avatar" class="header-profile__admin_img" @click="profileSettings = !profileSettings">
                <div class="header-profile__admin_name">{{admin.name}}</div>
                <div class="header-profile__admin_logout" @click="logoutUser">Выход</div>
                    <!-- <div v-if="profileSettings" class="header-profile__admin-settings">
                        <div class="header-profile__admin-settings_item">Профиль</div>
                        <div class="header-profile__admin-settings_item">Перезапустить приложение</div>
                        <div class="header-profile__admin-settings_item" @click="logoutUser">Выход</div>
                    </div> -->
            </div>
        </div>
    </div>
</template>

<script>
import {ref, onMounted} from "vue"
import {useAdmin} from "@/composition/useAdmin.js"
export default {
    methods: {
        logoutUser(){
            localStorage.removeItem("Token")
            this.$router.push({name: "Login"})
        },

        showMenu(){
            this.$emit('showMenuProfile')
        }
    },
    setup() {
        const {getProfile} = useAdmin()

        const admin = ref({})

        onMounted(() => {
          getProfile()
          .then((r) => {
              admin.value = r.data.data
          })  
        })
        const profileSettings = ref(false)
        return{
            admin,
            profileSettings,
        }
    }
}
</script>

<style lang="scss" src="@/styles/profile/headerProfile.scss"></style>