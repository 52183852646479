<template>
  <div class="topics" @click.self="closeModals">
    <div class="topics__header" @click.self="closeModals">
      <div class="topics__search" @click.self="closeModals">
        <!-- <input type="text" class="users__search_searchbar" placeholder="Поиск" v-model="search" @input="searchInputUsers"> -->

        <div class="searchBox">
          <input class="searchInput" type="text" name="" placeholder="Search" />
          <button class="searchButton" href="#">
            <magnify-icon title="search" />
          </button>
        </div>

        <div>
          <div class="topics__button pointer" @click="modalTopicOpen">Добавить</div>
          <div v-if="topicsAddedet" class="topics__addedet">
            <input
              v-model="addedetInput"
              type="text"
              class="topics__addedet_input"
              placeholder="Тема"
            />
            <div class="topics__addedet_add pointer" @click="createNewTopic">
              Добавить
            </div>
          </div>
          <div v-if="topicsChanged" class="topics__addedet">
            <input
              v-model="changedInput"
              type="text"
              class="topics__addedet_input"
              placeholder="Тема"
            />
            <div class="topics__addedet_add pointer" @click="changeTopics">Сохранить</div>
          </div>
        </div>
      </div>
      <div class="topics__table" @click.self="closeModals">
        <div class="topics__titles">
          <div class="topics__titles_email pointer">Название</div>
          <div class="topics__titles_control"></div>
        </div>
      </div>
    </div>
    <div class="topics__list" @click.self="closeModals">
      <div
        class="topics__item"
        v-for="(topic, index) in topics"
        :key="index"
        @click.self="closeModals(index)"
      >
        <div class="topics__item_email">{{ topic.name }}</div>
        <div class="topics__item_control">
          <!-- <div class="topics__item_button pointer" @click="activeDropdownMenu(index)">Управление</div>
                    <div v-if="dropdown[index].active" class="topics__item-drop-down">
                        <div class="topics__item-drop-down_item" @click="completionTopics(topic)">Изменить</div>
                        <div class="topics__item-drop-down_item" @click="deletes(topic.id)">Удаление</div>
                        <div class="topics__item-drop-down_item">Еще что то</div>
                    </div> -->
          <div class="topics__item-drop-down_new" @click="completionTopics(topic)">
            Изменить
          </div>
          <div class="topics__item-drop-down_new" @click="deletes(topic.id)">
            Удаление
          </div>
        </div>
      </div>
    </div>
  </div>
  <Loader v-if="loader" />
</template>
<script>
import MagnifyIcon from "vue-material-design-icons/Magnify.vue";
import { ref, onMounted, onUpdated } from "vue";
import { useTopics } from "@/composition/useTopics.js";
import Loader from "@/components/Loader.vue";
export default {
  components: {
    MagnifyIcon,
    Loader,
  },
  setup() {
    const { getTopics, topics, deleteTopics, createTopics, changeTopic } = useTopics();
    const loader = ref(false);
    const dropdown = ref([]);
    onMounted(() => {
      loader.value = true;
      getTopics()
        .then(() => {
          dropdown.value = topics.value.map((el) => ({ active: false }));
        })
        .finally(() => {
          loader.value = false;
        });
    });

    const activeDropdownMenu = (index) => {
      dropdown.value.forEach((el, i) => {
        if (index != i) {
          el.active = false;
        }
      });
      dropdown.value[index].active = !dropdown.value[index].active;
    };

    const deletes = (id) => {
      loader.value = true;
      deleteTopics(id).then(() => {
        getTopics()
          .then(() => {
            dropdown.value = topics.value.map((el) => ({ active: false }));
          })
          .finally(() => {
            loader.value = false;
          });
      });
    };

    const topicsAddedet = ref(false);
    const addedetInput = ref("");
    const createNewTopic = () => {
      loader.value = true;
      createTopics({ name: addedetInput.value }).then(() => {
        getTopics()
          .then(() => {
            dropdown.value = topics.value.map((el) => ({ active: false }));
            topicsAddedet.value = false;
            addedetInput.value = "";
          })
          .finally(() => {
            loader.value = false;
          });
      });
    };

    const topicsChanged = ref(false);
    const changedInput = ref("");

    const topicId = ref();
    const completionTopics = (topic) => {
      changedInput.value = topic.name;
      topicId.value = topic.id;
      topicsChanged.value = !topicsChanged.value;
      topicsAddedet.value = false;
    };
    const changeTopics = () => {
      loader.value = true;
      changeTopic({ name: changedInput.value }, topicId.value).then(() => {
        getTopics()
          .then(() => {
            dropdown.value = topics.value.map((el) => ({ active: false }));
            topicsChanged.value = false;
            changedInput.value = "";
          })
          .finally(() => {
            loader.value = false;
          });
      });
    };

    const closeModals = (index) => {
      topicsAddedet.value = false;
      topicsChanged.value = false;
      dropdown.value[index].active = false;
    };

    const modalTopicOpen = () => {
      topicsAddedet.value = !topicsAddedet.value;
      topicsChanged.value = false;
    };

    return {
      topics,
      dropdown,
      topicsAddedet,
      addedetInput,
      topicsChanged,
      changedInput,
      topicId,

      activeDropdownMenu,
      deletes,
      createNewTopic,
      changeTopics,
      completionTopics,
      closeModals,
      modalTopicOpen,

      loader,
    };
  },
};
</script>

<style lang="scss" src="@/styles/profile/topics/topics.scss"></style>
