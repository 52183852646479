
import axios from 'axios'
import {ref} from "vue"
import {useApi} from "@/composition/useApi.js"

export function usePages() {

    const { api, params } = useApi()
    const pages = ref()
    const getPages = () => {
       return axios.get(api + `/api/v1/cp/pages/`, params)
       .then((r) => {
        pages.value = r.data.data
       })
    }
    const changePage = (body, page_id) => {
        return axios.put(api + `/api/v1/cp/pages/${page_id}/`, body, params)
    }

    return {
        pages,
        getPages,
        changePage,
    }
}