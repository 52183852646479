<template>
<div class="user-subscrips-data__header">
    <div style="display: flex; width: 100%;">
        <div style="width: 100%;">
            <div style="margin-bottom: 10px; font-size: 18px" v-if="subscriptions">Подписки: {{subscriptions.length}}</div>
            <div class="user-subscrips-data__titles">
                <div class="user-subscrips-data__titles_avatar">Аватар</div>
                <div class="user-subscrips-data__titles_name">Имя</div>
                <div class="user-subscrips-data__titles_created">Email</div>
            </div>
        </div>
        <div style="width: 100%; margin-left: 40px;">
            <div style="margin-bottom: 10px; font-size: 18px" v-if="subscribers">Подписчики: {{subscribers.length}}</div>
            <div class="user-subscrips-data__titles">
                <div class="user-subscrips-data__titles_avatar">Аватар</div>
                <div class="user-subscrips-data__titles_name">Имя</div>
                <div class="user-subscrips-data__titles_created">Email</div>
            </div>
        </div>
    </div>
    <div style="display: flex; width: 100%;">
        <div style="width: 100%;">
            <div class="user-subscrips-data__list">
                <div class="user-subscrips-data__item" v-for="(subscription, index) in subscriptions" :key="index">
                    <img class="user-subscrips-data__item_avatar" :src="subscription.avatar">
                    <div class="user-subscrips-data__item_name">{{subscription.name}}</div>
                    <div class="user-subscrips-data__item_created">{{subscription.email}}</div>
                </div>
            </div>
        </div>
        <div style="width: 100%; margin-left: 40px;">
            <div class="user-subscrips-data__list">
                <div class="user-subscrips-data__item" v-for="(subscriber, index) in subscribers" :key="index">
                    <img class="user-subscrips-data__item_avatar" :src="subscriber.avatar" v-if="subscriber.avatar">
                    <img class="user-subscrips-data__item_avatar" src="@/assets/user.png" v-else>
                    <div class="user-subscrips-data__item_name">{{subscriber.name}}</div>
                    <div class="user-subscrips-data__item_created">{{subscriber.email}}</div>
                </div>
            </div>
        </div>
    </div>
</div>
<Loader v-if="loader"/>
</template>

<script>
import {
    ref,
    onMounted,
    onUpdated
} from "vue"
import {
    useUsers
} from "@/composition/useUsers.js"
import {
    useRoute
} from "vue-router"
import Loader from "@/components/Loader.vue"
export default {
    components:{
        Loader,
    },
    setup() {
        const {
            getSubscribers,
            getSubscriptions,
            subscriptions,
            subscribers,
        } = useUsers()
        const route = useRoute()
        const loader = ref(true)
        onMounted(() => {
            let user_id = route.params.id
            loader.value = true
            getSubscribers(user_id)
            .finally(() => {
                loader.value = false
            })
            loader.value = true
            getSubscriptions(user_id)
            .finally(() => {
                loader.value = false
            })
        })
        return {
            subscriptions,
            subscribers,
            loader,
        }
    }
}
</script>

<style lang="scss" src="@/styles/profile/users/userSubscripsData.scss"></style>
