<template>
  <div class="groups">
    <div class="groups__header">
      <!-- <div class="groups__search">
        <div class="searchBox">
          <input class="searchInput" type="text" name="" placeholder="Search" />
          <button class="searchButton" href="#">
            <magnify-icon title="search" />
          </button>
        </div>
      </div> -->
      <!-- <div class="groups__addeted">
        <div class="groups__addeted_add pointer" @click="openModalCreateGroup">
          Заявки на создание группы
        </div>
      </div> -->
    </div>
    <div class="groups__table">
      <div class="groups__titles">
        <div class="groups__titles_title">Название</div>
        <div class="groups__titles_teacher">Описание</div>
        <div class="groups__titles_img" style="text-align: left">Пользователь</div>
        <div class="groups__titles_time">Дата заявки</div>
        <div class="groups__titles_theme">Тема</div>
        <div class="groups__titles_limit">Статус</div>
        <div class="groups__titles_control"></div>
      </div>
    </div>
    <div class="groups__list">
      <div class="groups__item" v-for="(group, index) in applications" :key="index">
        <div class="groups__item_title">{{ group.title }}</div>
        <div class="groups__item_teacher">
          <div class="comment" :class="{ 'comment-open': comment[index].active }">
            {{ group.comment }}
          </div>
          <div
            class="more"
            v-if="group.comment.length > 50 && !comment[index].active"
            @click="openComment(index)"
          >
            Читать подробнее
          </div>
          <div class="more" v-else-if="comment[index].active" @click="openComment(index)">
            Свернуть
          </div>
        </div>
        <div class="groups__item_img" style="text-align: left; display: flex">
          Имя: {{ group.user.name }} <br />
          Email: {{ group.user.email }}
        </div>
        <div class="groups__item_time">
          {{ new Date(group.created * 1000).toLocaleString() }}
        </div>

        <div class="groups__item_theme" v-if="group.topic">
          {{ group.topic.name }}
        </div>
        <div class="groups__item_theme" v-else>Тема отсутсвует</div>

        <div class="groups__item_limit new" v-if="group.accepted == null">
          В обработке
        </div>
        <div class="groups__item_limit green" v-else-if="group.accepted == true">
          Принято
        </div>
        <div class="groups__item_limit red" v-else>Отклонено</div>

        <div class="groups__item_control">
          <div
            v-if="group.accepted == null"
            class="groups__item-drop-down_new"
            @click="changeApplication(group.id, false)"
          >
            Отклонить
          </div>
          <div
            v-if="group.accepted == true"
            class="groups__item-drop-down_new"
            @click="deleteAppl(group.id)"
          >
            Удалить
          </div>
          <!-- <div
            class="groups__item-drop-down_new"
            @click="openModalChangeGroup(group, index)"
          >
            Изменить
          </div> -->
          <div
            v-if="group.accepted == false || group.accepted == null"
            class="groups__item-drop-down_new"
            @click="changeApplication(group.id, true)"
          >
            Принять
          </div>
        </div>
      </div>
    </div>
    <div class="users__pagination" v-if="pagination">
      <img
        src="@/assets/arrow.svg"
        class="users__pagination_arrow pointer"
        @click="updateUserPagePrev(pagination.page)"
        v-if="pagination.has_prev"
      />
      <img
        src="@/assets/arrow.svg"
        class="users__pagination_arrow pointer"
        v-else
        :class="{ 'users__pagination_arrow-disabled': !pagination.has_prev }"
      />

      <div class="user__pagination_page">
        {{ pagination.page }} / {{ pagination.total }}
      </div>

      <img
        src="@/assets/arrow.svg"
        class="users__pagination_arrow pointer"
        @click="updateUserPageNext(pagination.page)"
        v-if="pagination.has_next"
      />
      <img
        src="@/assets/arrow.svg"
        class="users__pagination_arrow pointer"
        v-else
        :class="{ 'users__pagination_arrow-disabled': !pagination.has_next }"
      />
    </div>
  </div>
  <ModalCreateGroup v-if="modalCreateGroup" @close="closeModalCreateGroup" />
  <ModalChangeGroup
    v-if="modalChangeGroup"
    @close="closeModalChangeGroup"
    :group="groupData"
  />
  <Loader v-if="loader" />
</template>

<script>
import { ref, onMounted } from "vue";
import { useGroups } from "@/composition/useGroups.js";

import ModalCreateGroup from "@/components/Modals/ModalCreateGroup.vue";
import ModalChangeGroup from "@/components/Modals/ModalChangeGroup.vue";
import MagnifyIcon from "vue-material-design-icons/Magnify.vue";
import Loader from "@/components/Loader.vue";
export default {
  components: {
    ModalCreateGroup,
    ModalChangeGroup,
    MagnifyIcon,
    Loader,
  },
  methods: {
    route(id) {
      this.$router.push({ name: "Lesons", params: { id: id } });
    },
  },
  setup() {
    const {
      getApplications,
      applications,
      acceptApplication,
      pagination,
      deleteApplication,
    } = useGroups();

    const updateUserPagePrev = (page) => {
      loader.value = true;
      getApplications(page - 1).finally(() => {
        loader.value = false;
      });
    };

    const updateUserPageNext = (page) => {
      loader.value = true;
      getApplications(page + 1).finally(() => {
        loader.value = false;
      });
    };

    const loader = ref(false);
    const dropdown = ref([]);
    const comment = ref([]);
    onMounted(() => {
      loader.value = true;
      getApplications(1)
        .then(() => {
          comment.value = applications.value.map((el) => ({ active: false }));
        })
        .finally(() => {
          loader.value = false;
        });
    });

    const activeDropdownMenu = (index) => {
      comment.value.forEach((el, i) => {
        if (index != i) {
          el.active = false;
        }
      });
      comment.value[index].active = !dropdown.value[index].active;
    };

    const deleteAppl = (id) => {
      deleteApplication(id /* { accepted: true } */).then(() => {
        getApplications(1);
      });
    };

    const changeApplication = (id, variation) => {
      acceptApplication(id, { accepted: variation }).then(() => {
        getApplications(1);
      });
    };

    /* const deletes = (id, index) => {
      loader.value = true;
      deleteGroups(id)
        .then(() => {
          getApplications().then(() => {
            dropdown.value = applications.value.map((el) => ({
              active: false,
            }));
          });
        })
        .finally(() => {
          loader.value = false;
        });
      dropdown.value[index].active = false;
    }; */

    const modalCreateGroup = ref(false);
    const openModalCreateGroup = () => {
      modalCreateGroup.value = true;
    };
    const closeModalCreateGroup = () => {
      modalCreateGroup.value = false;
      loader.value = true;
      getApplications()
        .then(() => {
          dropdown.value = applications.value.map((el) => ({ active: false }));
        })
        .finally(() => {
          loader.value = false;
        });
    };

    const modalChangeGroup = ref(false);
    const groupData = ref(null);
    const openModalChangeGroup = (group, index) => {
      modalChangeGroup.value = true;
      groupData.value = group;
      comment.value[index].active = false;
    };
    const closeModalChangeGroup = () => {
      modalChangeGroup.value = false;
      loader.value = true;
      getApplications()
        .then(() => {
          comment.value = applications.value.map((el) => ({ active: false }));
        })
        .finally(() => {
          loader.value = false;
        });
    };

    const openComment = (i) => {
      comment.value[i].active = !comment.value[i].active;
    };

    return {
      /* groups, */
      applications,
      dropdown,
      activeDropdownMenu,

      modalCreateGroup,
      openModalCreateGroup,
      closeModalCreateGroup,

      modalChangeGroup,
      openModalChangeGroup,
      closeModalChangeGroup,

      changeApplication,

      openComment,
      comment,

      groupData,

      loader,

      pagination,
      updateUserPagePrev,
      updateUserPageNext,

      deleteAppl,
    };
  },
};
</script>

<style lang="scss" src="@/styles/profile/webinars/applications.scss" scoped></style>
