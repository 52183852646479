<template>
    <div>
        <div class="user-main-data__header" v-if="newDataUser">
            <div class="user-main-data__header-user">
                <img v-if="newDataUser.avatar" :src="newDataUser.avatar" class="user-main-data__header-user_img">
                <img v-else src="@/assets/user.png" class="user-main-data__header-user_img">
            </div>
            <div class="user-main-data__data-user">
                <div class="user-main-data__info">
                    <input type="text" class="user-main-data__info_input" v-model="newDataUser.name" disabled>
                    <input type="text" class="user-main-data__info_input" v-model="newDataUser.email" disabled>
                    <div class="user-main-data__btn-save" @click="openModalChangeUser">Изменить</div>
                </div>
                <div class="user-main-data__info">
                    <input type="text" class="user-main-data__info_input" v-model="activeAgeGroup" disabled>
                    <input type="text" class="user-main-data__info_input" v-model="activeGender" disabled>
                    <div class="user-main-data__btn-coin" :class="{'user-main-data__btn-coin-active': formCoinActive}" @click="formCoinActive = !formCoinActive">Начислить</div>
                </div>
                <div class="user-main-data__info">
                    <input type="text" class="user-main-data__info_input" style="opacity: 0;" disabled>
                    <input type="text" class="user-main-data__info_input" v-model="newDataUser.balance" disabled>
                    <div class="user-main-data__btn-delete">Удалить</div>
                </div>
            </div>
            <div class="form-coin" :class="{'form-coin-active' :formCoinActive}">
                <div class="form-coin__values">
                    <input type="text" class="form-coin__input" placeholder="Заголовок" v-model="dataCoin.title">
                    <textarea type="text" class="form-coin__input-area" style="height: 50px" placeholder="Коментарий" v-model="dataCoin.comment"></textarea>
                    <input type="text" class="form-coin__input" placeholder="Количество" v-model="dataCoin.amount">
                </div>
                <div class="form-coin__but" @click="sendCoin(newDataUser.id)">></div>
            </div>
        </div>
        <ModalChangeUser v-if="modalChangeUser" :data="user" @close="closeModalChangeUser"/>
    </div>
    <Loader v-if="loader"/>
</template>

<script>
import {
    ref,
    onMounted,
    onUpdated
} from "vue"
import Dropdown from "@/components/Dropdown.vue"
import DropdownChecked from "@/components/DropdownChecked.vue"
import ModalChangeUser from "@/components/Modals/ModalChangeUser.vue"
import {
    useUsers
} from "@/composition/useUsers.js"
import {
    useCoins
} from "@/composition/useCoins.js"
import {
    useRoute
} from "vue-router"
import Loader from "@/components/Loader.vue"
export default {
    components: {
        ModalChangeUser,
        Loader,
    },
    setup(props, {emit}) {
        const {
            getSelectUser,
            user,
            updateUser,
            deleteUser,
        } = useUsers()
        const {
            sendCoins,
        } = useCoins()
        const route = useRoute()

        const loader = ref(true)

        const formCoinActive = ref(false)
        const newDataUser = ref({})

        const age_group = ref([
            {id: 0, name: "от 0 до 16 лет"},
            {id: 1, name: "от 17 до 25 лет"},
            {id: 2, name: "от 26 до 40 лет"},
            {id: 3, name: "40+ лет"},
        ])
        const activeAgeGroup = ref("")

        const gender = ref([
            {id: 0, name: "Мужской"},
            {id: 1, name: "Женский"},
            {id: 2, name: "Отсутсвует"},
        ])
        const activeGender = ref("")

        const status = ref(
            [
                {name: "is_premium", label: 'Премиум', active: false},
                {name: "is_prof", label: 'Профессиональный', active: false},
                {name: "is_superuser", label: 'Суперпользователь', active: false},
            ]
        )
        const activeStatus = ref("")

        onMounted(() => {
            let user_id = route.params.id
            loader.value = true
            getSelectUser(user_id)
            .then(() => {
                newDataUser.value = {...user.value}
                console.log(user.value)
                for(let i=0; i < age_group.value.length; i++){
                    if(age_group.value[i].id == newDataUser.value.age_group){
                        activeAgeGroup.value = age_group.value[i].name
                    }
                }
                for(let i=0; i < gender.value.length; i++){
                    if(gender.value[i].id == newDataUser.value.gender){
                        activeGender.value = gender.value[i].name
                    } else {
                        activeGender.value = gender.value[2].name
                    }
                }

                /* for(let i=0; i < status.value.length; i++){ */
                    if(status.value[0].active == newDataUser.value.is_premium){
                        activeStatus.value = status.value[0].label
                    } else if (status.value[1].active == newDataUser.value.is_prof){
                         activeStatus.value = status.value[1].label
                    } else if (status.value[2].active == newDataUser.value.is_superuser){
                         activeStatus.value = status.value[2].label
                    } else {
                         activeStatus.value = "Пользователь"
                    }
                /* } */
            })
            .finally(() => {
                loader.value = false
            })
        })
        const dataCoin = ref({
            title: "",
            amount: null,
            comment: "",
        })
        const sendCoin = (id) => {
            loader.value = true
            sendCoins({
                title: dataCoin.value.title,
                amount: dataCoin.value.amount,
                comment: dataCoin.value.comment,
            }, id)
            .then(() => {
                dataCoin.value.title = ""
                dataCoin.value.amount = null
                dataCoin.value. comment = ""
                formCoinActive.value = false
            })
            .finally(() => {
                loader.value = false
            })
        }

        const modalChangeUser = ref(false)
        const openModalChangeUser = () => {
            modalChangeUser.value = true
        }

        const closeModalChangeUser = () => {
            modalChangeUser.value = false
        }

        return {
            newDataUser,
            formCoinActive,
            dataCoin,
            sendCoin,
            activeAgeGroup,
            activeGender,
            activeStatus,

            user,

            modalChangeUser,
            openModalChangeUser,
            closeModalChangeUser,

            loader,
        }
    }
}
</script>

<style lang="scss" src="@/styles/profile/users/userMainData.scss"></style>
