<template>
<div class="groups">
    <div class="groups__header">
        <div class="groups__search">
            <div class="searchBox">
                <input class="searchInput" type="text" name="" placeholder="Search">
                <button class="searchButton" href="#">
                    <magnify-icon title="search" />
                </button>
            </div>
        </div>
        <div class="groups__addeted">
            <div class="groups__button pointer" @click="topicsAddedet = !topicsAddedet">
                Добавить
            </div>
            <div v-if="topicsAddedet" class="groups__addedet">
                <input v-model="addedetInput" type="text" class="groups__addedet_input" placeholder="Номер занятия">
                <Datepicker class="groups__addedet_datepicker" v-model="date"/>
                <div class="groups__addedet_add pointer" @click="createNewLesson">Добавить</div>
            </div>
            <div v-if="topicsChanged" class="groups__addedet">
                <input v-model="changedInput" type="text" class="groups__addedet_input" placeholder="Номер занятия">
                <Datepicker class="groups__addedet_datepicker" v-model="dateChange"/>
                <div class="groups__addedet_add pointer" @click="changeNewLesson">Изменить</div>
            </div>
        </div>
    </div>
    <div class="groups__table">
        <div class="groups__titles">
            <div class="groups__titles_title">Номер занятия</div>
            <div class="groups__titles_teacher">Дата начала</div>
            <div class="groups__titles_control"></div>
        </div>
    </div>
    <div class="groups__list">
        <div class="groups__item" v-for="(lesson, index) in lessons" :key="index">
            <div class="groups__item_title">№ {{lesson.number}}</div>
            <div class="groups__item_teacher">{{new Date(lesson.starts * 1000).toLocaleString()}}</div>
            <div class="groups__item_control">
                <div class="groups__item_button pointer" @click="activeDropdownMenu(index)">Управление</div>
                <div v-if="dropdown[index].active" class="groups__item-drop-down">
                    <div class="groups__item-drop-down_item" @click="completionLesson(lesson)">Изменить</div>
                    <div class="groups__item-drop-down_item" @click="deletes(lesson.id)">Удаление</div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    ref,
    onMounted
} from "vue"
import {
    useGroups
} from "@/composition/useGroups.js"
import {
    useRoute
} from 'vue-router'
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import MagnifyIcon from 'vue-material-design-icons/Magnify.vue';
export default {
    /* methods: {
        route(id){
            this.$router.push({name: "Lesons", params: { id: id}})
        }
    }, */
    components: {
        Datepicker,
        MagnifyIcon,
    },
    setup() {
        const route = useRoute()
        const {
            getLessons,
            lessons,
            deleteLessons,
            createLesson,
            changeLesson,
        } = useGroups()

        const dropdown = ref([])
        const parametrIdGroup = ref(0)
        onMounted(() => {
            parametrIdGroup.value = route.params.id
            getLessons(parametrIdGroup.value)
                .then(() => {
                    dropdown.value = lessons.value.map((el) => ({
                        active: false
                    }))
                })
        })

        const activeDropdownMenu = (index) => {
            dropdown.value.forEach((el, i) => {
                if (index != i) {
                    el.active = false
                }
            });
            dropdown.value[index].active = !dropdown.value[index].active
        }

        const deletes = (id) => {
            deleteLessons(id)
                .then(() => {
                    getLessons(parametrIdGroup.value)
                        .then(() => {
                            dropdown.value = lessons.value.map((el) => ({
                                active: false
                            }))
                        })
                })
        }

        const topicsAddedet = ref(false)
        const addedetInput = ref(null)
        const date = ref(null)
        const createNewLesson = () => {
            createLesson({
                starts: new Date(date.value).getTime() / 1000,
                number: Number(addedetInput.value),
            }, parametrIdGroup.value)
            .then(() => {
                topicsAddedet.value = false
                date.value = null
                addedetInput.value = null
                getLessons(parametrIdGroup.value)
                .then(() => {
                    dropdown.value = lessons.value.map((el) => ({
                        active: false
                    }))
                })
            })
        }

        const topicsChanged = ref(false)
        const changedInput = ref(null)
        const dateChange = ref(null)

        const changeNewLesson = () => {
            changeLesson({
                starts: new Date(dateChange.value).getTime() / 1000,
                number: Number(changedInput.value),
            }, lessonId.value)
            .then(() => {
                topicsChanged.value = false
                dateChange.value = null
                changedInput.value = null
                getLessons(parametrIdGroup.value)
                .then(() => {
                    dropdown.value = lessons.value.map((el) => ({
                        active: false
                    }))
                })
            })
        }

        const lessonId = ref()
        const completionLesson = (lesson) => {
            changedInput.value = lesson.number
            dateChange.value = new Date(lesson.starts * 1000)
            console.log(lesson.starts)
            topicsChanged.value = true
            lessonId.value = lesson.id
        }

        return {
            dropdown,
            activeDropdownMenu,
            deletes,
            lessons,
            topicsAddedet,
            date,
            addedetInput,
            createNewLesson,
            topicsChanged,
            changedInput,
            dateChange,
            changeNewLesson,
            completionLesson,
            lessonId,
        }
    }
}
</script>

<style lang="scss" src="@/styles/profile/webinars/groups.scss"></style>
