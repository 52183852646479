<template>
    <div class="modalStories" @click.self="close">
        <div class="modalStories__form">
            <div class="modalStories__header">
                <div class="modalStories__header_title">История</div>
                <svg @click="close" class="modalStories__header_close pointer" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#333"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>
            </div>
            <div style="display: flex; flex-direction: row;">
                <input type="checkbox" v-model="storie.is_pinned" @click="changeStor">
                <span style="margin-left: 10px;">Закрепленная история</span>
            </div>
            <div class="modalStories__main">
                <swiper
                    :slides-per-view="1"
                    :space-between="50"
                    @swiper="onSwiper"
                    @slideChange="onSlideChange"
                    class="mySwiper swiper"
                >
                    <swiper-slide class="swiper-slide" v-if="storie.video">
                            <VuePlayerVideo :autoplay="true" :loop="true"  :hoverable="false" :controls="false" :src="storie.video.main_link"/>
                    </swiper-slide>
                    <swiper-slide class="swiper-slide" v-for="(gallery, index) in storie.gallery" :key="index">
                        <img  :src="gallery.main_link">
                    </swiper-slide>
                </swiper>
            </div>
            <div class="modalStories__footer">
                <div class="modalStories__footer_likes">
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M16.5 3c-1.74 0-3.41.81-4.5 2.09C10.91 3.81 9.24 3 7.5 3 4.42 3 2 5.42 2 8.5c0 3.78 3.4 6.86 8.55 11.54L12 21.35l1.45-1.32C18.6 15.36 22 12.28 22 8.5 22 5.42 19.58 3 16.5 3zm-4.4 15.55l-.1.1-.1-.1C7.14 14.24 4 11.39 4 8.5 4 6.5 5.5 5 7.5 5c1.54 0 3.04.99 3.57 2.36h1.87C13.46 5.99 14.96 5 16.5 5c2 0 3.5 1.5 3.5 3.5 0 2.89-3.14 5.74-7.9 10.05z"/></svg>
                    <p>{{storie.hugs_count}}</p>
                </div>
                <div class="modalStories__footer_views">
                    <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><g><rect fill="none" height="24" width="24"/><path d="M19,3H5C3.89,3,3,3.9,3,5v14c0,1.1,0.89,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.11,3,19,3z M19,19H5V7h14V19z M13.5,13 c0,0.83-0.67,1.5-1.5,1.5s-1.5-0.67-1.5-1.5c0-0.83,0.67-1.5,1.5-1.5S13.5,12.17,13.5,13z M12,9c-2.73,0-5.06,1.66-6,4 c0.94,2.34,3.27,4,6,4s5.06-1.66,6-4C17.06,10.66,14.73,9,12,9z M12,15.5c-1.38,0-2.5-1.12-2.5-2.5c0-1.38,1.12-2.5,2.5-2.5 c1.38,0,2.5,1.12,2.5,2.5C14.5,14.38,13.38,15.5,12,15.5z"/></g></svg>
                    <p>{{storie.views_count}}</p>
                </div>
            </div>
            <div class="modalStories__description">
                Описание: {{storie.text}}
            </div>
            <div class="modalStories__comments" v-if="comments">
                <div class="modalStories__item" v-for="(comment, index) in comments" :key="index">
                    <div class="modalStories__comments-name">
                        <div class="modalStories__comments-name_name">{{comment.user.name}}</div>
                        <div class="modalStories__comments-name_del pointer" @click="del(comment.id)">                   
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF"><path d="M0 0h24v24H0z" fill="none"/><path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"/></svg>
                        </div>
                    </div>
                    <div class="modalStories__comments-text">
                        <div class="modalStories__comments-text_text">{{comment.text}}</div>
                        <div class="modalStories__comments-text_date">{{new Date(comment.created * 1000).toLocaleString()}}</div>
                    </div>
                </div>
            </div>
            <div class="modalStories__comments" style="margin-top: 10px" v-else>
                Комментарии отсутсвуют
            </div>
            <!-- {{comments}} -->
        </div>
    </div>
    <Loader v-if="loader"/>
</template>

<script>
    import {ref, onMounted} from "vue"
    import {
    useGroups
    } from "@/composition/useGroups.js"
    import axios from 'axios'
    import Dropdown from "@/components/DropdownModal.vue"
    import PictureInput from 'vue-picture-input'

    import { Swiper } from 'swiper/vue/swiper.js'
    import {SwiperSlide} from 'swiper/vue/swiper-slide.js'

    import {useStories} from "@/composition/useStories.js"
    import "swiper/swiper.scss";
    import VuePlayerVideo from 'vue3-player-video'
    import Loader from "@/components/Loader.vue"
    export default {
        components: {
            PictureInput,
            Dropdown,
            Loader,
            Swiper,
            SwiperSlide,
            VuePlayerVideo,
        },
        props: {
            storie: Object,
        },
        data() {
            return {
                
            }
        },
        methods: {
            close(){
                this.$emit('close')
            }
        },
        setup(props, {emit}) {
            const {getComments, comments, delComments, changeStoryes} = useStories()
            const loader = ref(false)
            const isSpinned = ref(false)
            onMounted(() => {
                loader.value = true
                getComments(props.storie.id)
                .finally(() => {
                    loader.value = false
                })
                console.log(props.storie)
            })

            const del = (id) => {
                loader.value = true
                delComments(id)
                .then(() => {
                    getComments(props.storie.id)
                    .finally(() => {
                    loader.value = false
                })
                })
            }

            const changeStor = () => {
                setTimeout(() => {
                    changeStoryes({is_pinned: props.storie.is_pinned},props.storie.id)    
                }, 10);
            }
            return{
                comments,
                del,
                loader,
                isSpinned,
                changeStor,
            }
        }
    }
</script>

<style lang="scss" scoped>
    .modalStories{
        position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
        &__form{
            background: #FFFFFF;
            padding: 20px;
            width: 500px;
            border-radius: 8px;
            border: 1px solid #333;
        }
        &__header{
            display: flex;
            justify-content: space-between;
            align-items: center;
            &_title{
                color: #333;
                font-size: 20px;
            }
            &_close{
                
            }
        }
        &__main{
            display: flex;
            flex-direction: column;
            margin-top: 10px;
            
        }
        &__footer{
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
            &_likes{
                display: flex;
                align-items: center;
                & p {
                    margin: 0;
                    margin-left: 5px;
                    font-weight: 700;
                }
            }
            &_views{
                display: flex;
                align-items: center;
                & p {
                    margin: 0;
                    margin-left: 5px;
                    font-weight: 700;
                }
            }
        }
        &__description{
            margin-top: 10px;
            text-align: left;
            height: auto;
            max-height: 100px;
            overflow-y: auto;
            font-size: 12px;
        }
        &__comments{
            max-height: 100px;
            overflow-y: auto;
            height: auto;
        }
        &__comments-name{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 10px;
            font-size: 12px;
            font-weight: 900;
            &_name{
                font-size: 12px;
            }
            &_del{
                background: #f52222;
                color: #ffffff;
                padding: 0px 4px;
                border-radius: 8px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 25px;
            }
        }
        &__comments-text{
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            margin-top: 5px;
            font-size: 10px;
            &_text{
                text-align: left;
            }
            &_date{

            }
        }
    }
</style>