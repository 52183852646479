<template>
    <div class="modalChangeUser">
        <div class="modalChangeUser__form">
            <div class="modalChangeUser__header">
                <div class="modalChangeUser__header_title">Изменение пользователя</div>
                <svg @click="close" class="modalChangeUser__header_close pointer" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#333"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>
            </div>
            <div class="modalChangeUser__main" v-if="dataUser">
                <input class="modalChangeUser__main_input" type="text" v-model="dataUser.name">
                <input class="modalChangeUser__main_input" type="text" v-model="dataUser.email">
                <div class="modalChangeUser__main_item">
                    <div class="modalChangeUser__main_item-name">Доступ к чатам</div>
                    <Toggle class="modalChangeUser__main_item-toggle" v-model="dataUser.is_available_for_chatting" />
                </div>
                <textarea class="modalChangeUser__main_textarea" name="" id="" cols="30" rows="10" v-model="dataUser.self_description"></textarea>
                <div class="modalChangeUser__main_item">
                    <div class="modalChangeUser__main_item-name">Активный пользователь</div>
                    <Toggle class="modalChangeUser__main_item-toggle" v-model="dataUser.is_active" />
                </div>
                <div class="modalChangeUser__main_item">
                    <div class="modalChangeUser__main_item-name">Профи</div>
                    <Toggle class="modalChangeUser__main_item-toggle" v-model="dataUser.is_prof" />
                </div>
                <div class="modalChangeUser__main_item">
                    <div class="modalChangeUser__main_item-name">Премиум доступ</div>
                    <Toggle class="modalChangeUser__main_item-toggle" v-model="dataUser.is_premium" />
                </div>
                <div class="modalChangeUser__main_item">
                    <div class="modalChangeUser__main_item-name">Суперпользователь</div>
                    <Toggle class="modalChangeUser__main_item-toggle" v-model="dataUser.is_superuser" />
                </div>
            </div>
            <div class="modalChangeUser__buttons">
                <div class="modalChangeUser__buttons_cancel pointer" @click="close">Отмена</div>
                <div class="modalChangeUser__buttons_save pointer" @click="update">Изменить</div>
            </div>
        </div>
    </div>
    <Loader v-if="loader"/>
</template>

<script>
import {ref, onMounted} from "vue"
import Toggle from '@vueform/toggle'
import {useUsers} from "@/composition/useUsers.js"
import Loader from "@/components/Loader.vue"
export default {
    components: {
      Toggle,
      Loader,
    },
    props: {
        data: Object,
    },
    setup(props, {emit}) {
        const {
            updateUser,
        } = useUsers()
        const close = () => {
            emit('close')
        }
        const dataUser = ref()
        const loader = ref(false)
        onMounted(() => {
          dataUser.value = props.data  
        })

        const update = () => {
            loader.value = true
            updateUser({
                name: dataUser.value.name,
                is_available_for_chatting: dataUser.value.is_available_for_chatting,
                self_description: dataUser.value.self_description,
                email: dataUser.value.email,
                is_active: dataUser.value.is_active,
                is_prof: dataUser.value.is_prof,
                is_premium: dataUser.value.is_premium,
                is_superuser: dataUser.value.is_superuser
            }, dataUser.value.id)
            .then(() => {
                emit('close')
            })
            .finally(() => {
                loader.value = false
            })
        }
        return{
            close,
            dataUser,

            update,

            loader,
        }
    }
}
</script>

<style lang="scss">
    .modalChangeUser{
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: table;
        transition: opacity 0.3s ease;
        display: flex;
        align-items: center;
        justify-content: center;
        &__form{
            background: #FFFFFF;
            padding: 20px;
            width: 400px;
            border-radius: 8px;
            border: 1px solid #333;
        }
        &__header{
            display: flex;
            justify-content: space-between;
            align-items: center;
            &_title{
                color: #333;
                font-size: 20px;
            }
            &_close{
                
            }
        }
        &__main{
            display: flex;
            flex-direction: column;
            margin-top: 10px;
            &_item{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 10px;
            }
            &_input{
                margin-top: 10px;
                border: 2px solid #333;
                border-radius: 4px;
                padding: 5px;
            }
            &_textarea{
                margin-top: 10px;
                border: 2px solid #333;
                border-radius: 4px;
                padding: 5px;
            }
            &_item-name{
                color: #333;
                font-size: 14px;
            }
            &_item-toggle{
                margin-left: 20px;
            }
        }
        &__buttons{
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
            &_cancel{
                padding: 10px 40px;
                background: #8f4aeb;
                color: #FFFFFF;
                border-radius: 8px;
            }
            &_save{
                padding: 10px 40px;
                background: #8f4aeb;
                color: #FFFFFF;
                border-radius: 8px;
            }
        }
    }

    .toggle-container {
  display: inline-block;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 var(--toggle-ring-width, 3px) var(--toggle-ring-color, #10B98130);
  }
}

.toggle {
  display: flex;
  width: var(--toggle-width, 3rem);
  height: var(--toggle-height, 1.25rem);
  border-radius: 999px;
  position: relative;
  cursor: pointer;
  transition: .3s all;
  align-items: center;
  box-sizing: content-box;
  border: var(--toggle-border, 0.125rem) solid;
  font-size: var(--toggle-font-size, 0.75rem);
  line-height: 1;
}

.toggle-on {
  background: var(--toggle-bg-on, #8f4aeb);
  border-color: var(--toggle-border-on, #8f4aeb);
  justify-content: flex-start;
  color: var(--toggle-text-on, #ffffff);
}

.toggle-off {
  background: var(--toggle-bg-off, #e5e7eb);
  border-color: var(--toggle-border-off, #e5e7eb);
  justify-content: flex-end;
  color: var(--toggle-text-off, #374151);
}

.toggle-on-disabled {
  background: var(--toggle-bg-on-disabled, #d1d5db);
  border-color: var(--toggle-border-on-disabled, #d1d5db);
  justify-content: flex-start;
  color: var(--toggle-text-on-disabled, #9ca3af);
  cursor: not-allowed;
}

.toggle-off-disabled {
  background: var(--toggle-bg-off-disabled, #e5e7eb);
  border-color: var(--toggle-border-off-disabled, #e5e7eb);
  justify-content: flex-end;
  color: var(--toggle-text-off-disabled, #9ca3af);
  cursor: not-allowed;
}

.toggle-handle {
  display: inline-block;
  background: var(--toggle-handle-enabled, #ffffff);
  width: var(--toggle-height, 1.25rem);
  height: var(--toggle-height, 1.25rem);
  top: 0;
  border-radius: 50%;
  position: absolute;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: var(--toggle-duration, 150ms);
}

.toggle-handle-on {
  left: 100%;
  transform: translateX(-100%);
}

.toggle-handle-off {
  left: 0%;
}

.toggle-handle-on-disabled {
  left: 100%;
  transform: translateX(-100%);
  background: var(--toggle-handle-disabled, #f3f4f6);
}

.toggle-handle-off-disabled {
  left: 0%;
  background: var(--toggle-handle-disabled, #f3f4f6);
}

.toggle-label {
  text-align: center;
  width: calc(var(--toggle-width, 3.25rem) - var(--toggle-height, 1.25rem));
  box-sizing: border-box;
  white-space: nowrap;
  user-select: none;
}

    .my-toggle{
        --toggle-width: 3rem;
        --toggle-height: 1.25rem;
        --toggle-border: 0.125rem;
        --toggle-font-size: 0.75rem;
        --toggle-duration: 150ms;
        --toggle-bg-on: #8f4aeb;
        --toggle-bg-off: #e5e7eb;
        --toggle-bg-on-disabled: #d1d5db;
        --toggle-bg-off-disabled: #e5e7eb;
        --toggle-border-on: #8f4aeb;
        --toggle-border-off: #e5e7eb;
        --toggle-border-on-disabled: #d1d5db;
        --toggle-border-off-disabled: #e5e7eb;
        --toggle-text-on: #ffffff;
        --toggle-text-off: #374151;
        --toggle-text-on-disabled: #9ca3af;
        --toggle-text-off-disabled: #9ca3af;
        --toggle-handle-enabled: #ffffff;
        --toggle-handle-disabled: #f3f4f6;
    }
</style>