<template>
  <div class="groups" @click.self="closeModals">
    <div class="groups__header" @click.self="closeModals">
      <div class="groups__search">
        <div class="searchBox">
          <input
            class="searchInput"
            type="text"
            name=""
            placeholder="Search"
            v-model="search"
            @input="searchData"
          />
          <button class="searchButton" href="#">
            <magnify-icon title="search" />
          </button>
        </div>
      </div>
      <div class="groups__addeted">
        <div class="groups__button pointer" @click="modalTopicOpen">Добавить</div>
        <div v-if="topicsAddedet" class="groups__addedet">
          <input
            v-model="addedetInput"
            type="text"
            class="groups__addedet_input"
            placeholder="Номер занятия"
          />
          <Datepicker class="groups__addedet_datepicker" v-model="date" />
          <div class="groups__addedet_add pointer" @click="createNewLesson">Добавить</div>
        </div>
        <div v-if="topicsChanged" class="groups__addedet">
          <input
            v-model="changedInput"
            type="text"
            class="groups__addedet_input"
            placeholder="Номер занятия"
          />
          <Datepicker class="groups__addedet_datepicker" v-model="dateChange" />
          <div class="groups__addedet_add pointer" @click="changeNewLesson">Изменить</div>
        </div>
      </div>
    </div>
    <div class="groups__table" @click.self="closeModals">
      <div class="groups__titles">
        <div class="groups__titles_title-lesson">Номер занятия</div>
        <div class="groups__titles_teacher-lesson">Дата начала</div>
        <div class="groups__titles_control-lesson"></div>
      </div>
    </div>
    <div class="groups__list" @click.self="closeModals">
      <div
        class="groups__item"
        v-for="(lesson, index) in lessons"
        :key="index"
        @click.self="closeModals"
      >
        <div class="groups__item_title-lesson" @click.self="closeModals">
          № {{ lesson.number }}
        </div>
        <div class="groups__item_teacher-lesson" @click.self="closeModals">
          {{ new Date(lesson.starts * 1000).toLocaleString() }}
        </div>
        <div class="groups__item_control-lesson" @click.self="closeModals">
          <div class="groups__item-drop-down_new" @click="downloadEmailList(lesson.id)">
            Email
          </div>
          <div class="groups__item-drop-down_new" @click="completionLesson(lesson)">
            Изменить
          </div>
          <div class="groups__item-drop-down_new" @click="deletes(lesson.id)">
            Удаление
          </div>
        </div>
      </div>
    </div>

    <div class="users__pagination" v-if="pagination">
      <img
        src="@/assets/arrow.svg"
        class="users__pagination_arrow pointer"
        @click="updateUserPagePrev(pagination.page)"
        v-if="pagination.has_prev"
      />
      <img
        src="@/assets/arrow.svg"
        class="users__pagination_arrow pointer"
        v-else
        :class="{ 'users__pagination_arrow-disabled': !pagination.has_prev }"
      />

      <div class="user__pagination_page">
        {{ pagination.page }} / {{ pagination.total }}
      </div>

      <img
        src="@/assets/arrow.svg"
        class="users__pagination_arrow pointer"
        @click="updateUserPageNext(pagination.page)"
        v-if="pagination.has_next"
      />
      <img
        src="@/assets/arrow.svg"
        class="users__pagination_arrow pointer"
        v-else
        :class="{ 'users__pagination_arrow-disabled': !pagination.has_next }"
      />
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import { useGroups } from "@/composition/useGroups.js";
import { useRoute } from "vue-router";
import {useApi} from "@/composition/useApi.js"
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import MagnifyIcon from "vue-material-design-icons/Magnify.vue";
import Loader from "@/components/Loader.vue";
export default {
  /* methods: {
        route(id){
            this.$router.push({name: "Lesons", params: { id: id}})
        }
    }, */
  components: {
    Datepicker,
    MagnifyIcon,
    Loader,
  },
  setup() {
    const route = useRoute();
    const {
      getLessons,
      lessons,
      deleteLessons,
      createLesson,
      changeLesson,
      pagination,
    } = useGroups();

    const loader = ref(false);
    const updateUserPagePrev = (page) => {
      loader.value = true;
      getLessons(parametrIdGroup.value, page - 1).finally(() => {
        loader.value = false;
      });
    };

    const updateUserPageNext = (page) => {
      loader.value = true;
      getLessons(parametrIdGroup.value, page + 1).finally(() => {
        loader.value = false;
      });
    };

    const dropdown = ref([]);
    const parametrIdGroup = ref(0);
    onMounted(() => {
      parametrIdGroup.value = route.params.id;
      getLessons(parametrIdGroup.value, 1).then(() => {
        dropdown.value = lessons.value.map((el) => ({
          active: false,
        }));
      });
    });

    // Я НЕ ЗНАЮ JS, ИЗВИНИТЕ, МНЕ СТРАШНО
    function downloadEmailList(lessonId) {
        const { api, params } = useApi();
        const url = `${api}/api/v1/cp/lessons/${lessonId}/users`;
        fetch(url, params)
            .then(response => response.json())
            .then(data => {
                const emails = data.data.map(user => user.email);
                const emailList = emails.join('\n');

                const blob = new Blob([emailList], { type: 'text/plain' });

                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'email_list.txt';
                document.body.appendChild(a);
                a.click();

                URL.revokeObjectURL(url);
            })
            .catch(error => console.error('Ошибка при загрузке данных:', error));
    }

    const activeDropdownMenu = (index) => {
      dropdown.value.forEach((el, i) => {
        if (index != i) {
          el.active = false;
        }
      });
      dropdown.value[index].active = !dropdown.value[index].active;
    };

    const deletes = (id) => {
      deleteLessons(id).then(() => {
        getLessons(parametrIdGroup.value, 1).then(() => {
          dropdown.value = lessons.value.map((el) => ({
            active: false,
          }));
        });
      });
    };

    const topicsAddedet = ref(false);
    const addedetInput = ref(null);
    const date = ref(null);
    const createNewLesson = () => {
      createLesson(
        {
          starts: new Date(date.value).getTime() / 1000,
          number: Number(addedetInput.value),
        },
        parametrIdGroup.value
      ).then(() => {
        topicsAddedet.value = false;
        date.value = null;
        addedetInput.value = null;
        getLessons(parametrIdGroup.value, 1).then(() => {
          dropdown.value = lessons.value.map((el) => ({
            active: false,
          }));
        });
      });
    };

    const topicsChanged = ref(false);
    const changedInput = ref(null);
    const dateChange = ref(null);

    const changeNewLesson = () => {
      changeLesson(
        {
          starts: new Date(dateChange.value).getTime() / 1000,
          number: Number(changedInput.value),
        },
        lessonId.value
      ).then(() => {
        topicsChanged.value = false;
        dateChange.value = null;
        changedInput.value = null;
        getLessons(parametrIdGroup.value, 1).then(() => {
          dropdown.value = lessons.value.map((el) => ({
            active: false,
          }));
        });
      });
    };

    const lessonId = ref();
    const completionLesson = (lesson) => {
      changedInput.value = lesson.number;
      dateChange.value = new Date(lesson.starts * 1000);
      console.log(lesson.starts);
      topicsChanged.value = true;
      lessonId.value = lesson.id;
    };

    const modalTopicOpen = () => {
      topicsAddedet.value = !topicsAddedet.value;
      topicsChanged.value = false;
    };
    const closeModals = () => {
      topicsAddedet.value = false;
      topicsChanged.value = false;
    };

    const search = ref("");

    const searchData = computed(() => {
      if (search.value != "") {
        return lessons.value.filter((element) => element.number.includes(search.value));
      }
    });

    /* const filteredPoints = computed(()=>{
            if(points.value){
                return CityPoints.value.filter(point => (point.name+" "+point.subname+" "+point.address).toLowerCase().includes(props.searchInput.toLowerCase())
                )
            }
        })
        const CityPoints = computed(()=>{
            if(points.value){
                return points.value.filter(point => point.city.name == props.city)
            }
        }) */

    return {
      dropdown,
      activeDropdownMenu,
      deletes,
      lessons,
      topicsAddedet,
      date,
      addedetInput,
      createNewLesson,
      topicsChanged,
      changedInput,
      dateChange,
      changeNewLesson,
      completionLesson,
      lessonId,
      modalTopicOpen,
      closeModals,
      search,
      searchData,
      pagination,
      updateUserPagePrev,
      updateUserPageNext,
      loader,
      downloadEmailList,
    };
  },
};
</script>

<style lang="scss" src="@/styles/profile/webinars/groups.scss"></style>
