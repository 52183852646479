
import axios from 'axios'
import {ref} from "vue"
import {useApi} from "@/composition/useApi.js"

export function useStories() {

    const { api, params } = useApi()
    const stories = ref()
    const comments = ref()
    const getStories = (user_id) => {
       return axios.get(api + `/api/v1/cp/users/${user_id}/stories/`, params)
       .then((r) => {
        stories.value = r.data.data
       })
    }
    const deleteStories = (story_id) => {
        return axios.delete(api + `/api/v1/cp/stories/${story_id}/`, params)
    }

    const getComments = (story_id) => {
        return axios.get(api + `/api/v1/cp/stories/${story_id}/comments/`, params)
        .then((r) => {
            comments.value = r.data.data
        })
    }

    const delComments = (comment_id) => {
        return axios.delete(api + `/api/v1/cp/comments/${comment_id}/`, params)
    }

    const changeStoryes = (body, story_id) => {
        return axios.put(api + `/api/v1/cp/stories/${story_id}/`, body, params)
    }

    return {
        stories,
        getStories,
        deleteStories,
        comments,
        getComments,
        delComments,
        changeStoryes,
    }
}