
import axios from 'axios'
import {ref} from "vue"
import {useApi} from "@/composition/useApi.js"

export function useAdmin() {

    const { api, params } = useApi()

    const getProfile = () => {
       return axios.get(api + `/api/v1/users/me/`, params)
    }

    return {
        getProfile
    }
}