<template>
<div class="user-referals-data__header">
    <div class="user-referals-data__titles">
        <div class="user-referals-data__titles_avatar">Имя</div>
        <div class="user-referals-data__titles_name">IP</div>
        <div class="user-referals-data__titles_created">Дата регистрации</div>
    </div>
    <div class="user-referals-data__list">
        <div class="user-referals-data__item" v-for="(device, index) in devices" :key="index">
            <div class="user-referals-data__item_avatar">{{device.detected_os}}</div>
            <div class="user-referals-data__item_name" v-if="device.x_real_ip">{{device.x_real_ip}}</div>
            <div class="user-referals-data__item_name" v-else>{{device.ip_address}}</div>
            <div class="user-referals-data__item_created">{{new Date(device.created * 1000).toLocaleString()}}</div>
        </div>
    </div>
</div>
<Loader v-if="loader"/>
</template>

<script>
import {
    ref,
    onMounted,
    onUpdated
} from "vue"
import {
    useUsers
} from "@/composition/useUsers.js"
import {
    useRoute
} from "vue-router"
import Loader from "@/components/Loader.vue"
export default {
    components:{
        Loader
    },
    setup() {
        const {
            getDevicesUser,
            devices
        } = useUsers()
        const loader = ref(true)
        const route = useRoute()

        onMounted(() => {
            let user_id = route.params.id
            loader.value = true
            getDevicesUser(user_id)
            .finally(() => {
                loader.value = false
            })
        })
        return {
            devices,
            loader
        }
    }
}
</script>

<style lang="scss" src="@/styles/profile/users/userReferalsData.scss"></style>
