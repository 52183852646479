<template>
  <div class="menu-profile">
    <div class="menu-profile__wrapper">
      <div class="menu-profile__list">
        <div
          class="menu-profile__item"
          v-for="(navigation, index) in navigations"
          :key="index"
        >
          <template v-if="navigation.links.length > 1">
            <div
              class="menu-profile__item-parent pointer"
              :class="{ 'menu-profile__item-parent-active': navigation.active }"
              @click="openNavigation(index)"
            >
              <img style="width: 25px" :src="require(`@/assets/${navigation.svg}.svg`)" />
              <div class="menu-profile__item-parent_name">
                {{ navigation.title }}
              </div>
              <img
                src="@/assets/arrow.svg"
                alt="arrow"
                class="menu-profile__item-parent_arrow"
                :class="{ reflect: navigation.active }"
              />
            </div>
            <template v-if="navigation.active">
              <template v-for="(link, jindex) in navigation.links" :key="jindex">
                <router-link :to="link.link" class="menu-profile__item-child">
                  <div class="menu-profile__item-child_name">
                    {{ link.title }}
                  </div>
                </router-link>
              </template>
            </template>
          </template>
          <template v-else>
            <template v-for="(link, jindex) in navigation.links" :key="jindex">
              <router-link :to="link.link" class="menu-profile__item-parent">
                <img style="width: 25px" :src="require(`@/assets/${link.svg}.svg`)" />
                <div class="menu-profile__item-parent_name" style="text-align: left">
                  {{ link.title }}
                </div>
              </router-link>
            </template>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: false,
      navigations: [
        {
          title: "Пользователи",
          active: false,
          svg: "users-t",
          links: [
            {
              title: "Пользователи",
              link: "/profile/users/userlist",
              svg: "users-t",
            },
            {
              title: "Жалобы на пользователей",
              link: "/profile/users/reports-users",
              svg: "reports-users",
            },
            {
              title: "Жалобы на истории",
              link: "/profile/users/reports-history",
              svg: "reports-history",
            },
          ],
        },

        {
          title: "Группы",
          active: false,
          svg: "groups",
          links: [
            {
              title: "Список групп",
              link: "/profile/webinars/groups",
              svg: "groups",
            },
            {
              title: "Заявки на создание группы",
              link: "/profile/applications",
              svg: "documents",
            },
          ],
        },
        /* {
          title: "Заявки на создание группы",
          active: false,
          links: [
            {
              title: "Заявки на создание группы",
              link: "/profile/applications",
              svg: "documents",
            },
          ],
        }, */
        {
          title: "Темы",
          active: false,
          links: [
            {
              title: "Темы",
              link: "/profile/topics/topicsList",
              svg: "topics",
            },
          ],
        },

        {
          title: "Страницы",
          active: false,
          links: [
            {
              title: "Страницы",
              link: "/profile/pages/pagesList",
              svg: "pages",
            },
          ],
        },

        {
          title: "Статистика",
          active: false,
          links: [
            {
              title: "Статистика",
              link: "/profile/dashboard",
              svg: "dashboard",
            },
          ],
        },
      ],
    };
  },

  methods: {
    openNavigation(index) {
      this.navigations[index].active = !this.navigations[index].active;
    },
  },

  mounted() {
    /* this.navigations = this.navigations.map(el => {return{el, active: false}})
        console.log(this.navigations) */

    this.navigations.forEach((el) => {
      el.links.forEach((link) => {
        if (link.link == this.$route.fullPath) {
          /* this.active = true */
          el.active = true;
        } else {
          /* this.active = false */
          el.active = false;
        }
      });
    });
  },
};
</script>

<style lang="scss" src="@/styles/profile/menuProfile.scss"></style>
