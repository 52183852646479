
import axios from 'axios'
import {ref} from "vue"
import {useApi} from "@/composition/useApi.js"

export function useLogin() {

    const { api } = useApi()

    const loginUser = (dataUser) => {
        let body = {
            ...dataUser,
        }
        return axios.post(api + '/api/v1/strict/login/access-token/', body)
        .then((r) => {
            localStorage.setItem("Token", r.data.data.access_token)
        })
    }

    const recoverUser = (dataUser) => {
        let body = {
            ...dataUser,
        }
        return axios.post(api + '/api/v1/recover-password/', body)
    }

    const logoutUser = () => {
        localStorage.removeItem("Token")
    }


    return {
        loginUser,
        recoverUser,
        logoutUser,
    }
}