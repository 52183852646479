<template>
  <div class="dashboard-statistic">
    <div class="dashboard-statistic__cards">
      <div class="dashboard-statistic__item title">
        Пользователей всего: {{ users.total_users }}
      </div>
      <div class="dashboard-statistic__item ml-20 title">
        Покупок премиум всего: {{ users.total_premium }}
      </div>
      <div class="dashboard-statistic__item ml-20 title">
        Покупок премиум за месяц: {{ users.current_premium }}
      </div>
    </div>

    <!-- <div class="dashboard-statistic__graph">
      <div class="dashboard-statistic__item_graph">
        <div class="title">Пользователей по возрастным группам</div>
        <div class="dashboard-statistic__item_graph_static">
          <DoughnutChart :chartData="ageGroupsUsers" />
        </div>
      </div>
    </div> -->
  </div>
  <!--   <Loader v-if="loader" /> -->
</template>
<script>
import { ref, onMounted } from "vue";
import Loader from "@/components/Loader.vue";

import { defineComponent } from "vue";
import { DoughnutChart } from "vue-chart-3";
import { Chart, registerables } from "chart.js";

import { useUsers } from "@/composition/useUsers.js";

Chart.register(...registerables);
export default defineComponent({
  components: {
    Loader,
    DoughnutChart,
  },
  setup(prosp, { emit }) {
    const { getStatisticUsers } = useUsers();
    const loader = ref(false);

    const ageGroupsLength = ref({
      oneGroup: 10,
      twoGroup: 20,
      threeGroup: 30,
      fourGroup: 40,
    });

    const users = ref({
      total_users: 0,
      total_premium: 0,
      current_premium: 0,
    });

    onMounted(() => {
      loader.value = true;
      getStatisticUsers()
        .then((r) => {
          users.value = r.data.data;
        })
        .finally(() => {
          loader.value = false;
        });
    });

    const ageGroupsUsers = {
      labels: ["Всего пользователей", "Преим", "от 25-40 лет", "40+ лет"],
      datasets: [
        {
          data: [
            ageGroupsLength.value.oneGroup,
            ageGroupsLength.value.twoGroup,
            ageGroupsLength.value.threeGroup,
            ageGroupsLength.value.fourGroup,
          ],
          backgroundColor: ["#77CEFF", "#0079AF", "#123E6B", "#97B0C4"],
        },
      ],
    };

    return {
      loader,
      ageGroupsUsers,
      ageGroupsLength,
      users,
    };
  },
});
</script>

<style lang="scss" src="@/styles/profile/dashboard/dashboard.scss"></style>
