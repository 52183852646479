<template>
  <div class="loader">
    <div class="preloader loading">
      <span class="slice"></span>
      <span class="slice"></span>
      <span class="slice"></span>
      <span class="slice"></span>
      <span class="slice"></span>
      <span class="slice"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Hexagon",
};
</script>

<style lang="scss" scoped>
@for $i from 1 through 6 {
  @keyframes preload-show-#{$i} {
    from {
      transform: rotateZ(60 * $i + deg) rotateY(-90deg) rotateX(0deg);
      border-left-color: #8f4aeb;
    }
  }
  @keyframes preload-hide-#{$i} {
    to {
      transform: rotateZ(60 * $i + deg) rotateY(-90deg) rotateX(0deg);
      border-left-color: #8f4aeb;
    }
  }

  @keyframes preload-cycle-#{$i} {
    $startIndex: $i * 5;
    $reverseIndex: (80 - $i * 5);

    #{$startIndex * 1%} {
      transform: rotateZ(60 * $i + deg) rotateY(90deg) rotateX(0deg);
      border-left-color: #8f4aeb;
    }
    #{$startIndex + 5%},
    #{$reverseIndex * 1%} {
      transform: rotateZ(60 * $i + deg) rotateY(0) rotateX(0deg);
      border-left-color: #ba8cf7;
    }

    #{$reverseIndex + 5%},
    100% {
      transform: rotateZ(60 * $i + deg) rotateY(90deg) rotateX(0deg);
      border-left-color: #8f4aeb;
    }
  }
}

@keyframes preload-flip {
  0% {
    transform: rotateY(0deg) rotateZ(-60deg);
  }
  100% {
    transform: rotateY(360deg) rotateZ(-60deg);
  }
}

body {
  background: #efefef;
}

.loader {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.preloader {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 20px;
  display: block;
  width: 3.75em;
  height: 4.25em;
  margin-left: -1.875em;
  margin-top: -2.125em;
  transform-origin: center center;
  transform: rotateY(180deg) rotateZ(-60deg);

  .slice {
    border-top: 1.125em solid transparent;
    border-right: none;
    border-bottom: 1em solid transparent;
    border-left: 1.875em solid #ba8cf7;
    position: absolute;
    top: 0px;
    left: 50%;
    transform-origin: left bottom;
    border-radius: 3px 3px 0 0;
  }

  @for $i from 1 through 6 {
    .slice:nth-child(#{$i}) {
      transform: rotateZ(60 * $i + deg) rotateY(0deg) rotateX(0);
      animation: 0.15s linear 0.9 - $i * 0.08s preload-hide-#{$i} both 1;
    }
  }

  &.loading {
    animation: 2s preload-flip steps(2) infinite both;
    @for $i from 1 through 6 {
      .slice:nth-child(#{$i}) {
        transform: rotateZ(60 * $i + deg) rotateY(90deg) rotateX(0);
        animation: 2s preload-cycle-#{$i} linear infinite both;
      }
    }
  }
}
</style>
