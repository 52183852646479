<template>
  <router-view></router-view>
</template>

<script>
import Autorization from "@/views/Autorization/Autorization.vue";
import Profile from "@/views/Profile/Profile.vue";
export default {
  components: {
    Autorization,
    Profile,
  },
  mounted() {
    if (localStorage.getItem("Token") == undefined || !localStorage.getItem("Token")) {
      this.$router.push({ name: "Login" });
      localStorage.removeItem("Token");
    } else {
      /* this.$router.push({ name: "Profile" }); */
    }
  },
};
</script>

<style lang="scss">
*,
:after,
:before {
  box-sizing: border-box;
}

* {
  transition: all 0.1s linear;
}
input {
  border: 1px solid #333;
  border-radius: 3px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;
    text-decoration: none !important;
    &.router-link-exact-active {
      color: #42b983;
      text-decoration: none;
    }
  }
}

.router-link-exact-active {
  background: rgb(17, 17, 17);
  text-decoration: none;
}

body {
  margin: 0;
}

.pointer {
  cursor: pointer;
}

.reflect {
  transform: rotate(180deg);
}
</style>
