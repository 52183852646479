<template>
  <div class="pageslist">
    <div class="pageslist__titles">
      <div class="pageslist__titles_title">Заголовок</div>
      <div class="pageslist__titles_body">Текст</div>
      <div class="pageslist__titles_controls"></div>
    </div>
    <div class="pageslist__list">
      <div class="pageslist__item" v-for="(page, index) in pages" :key="index">
        <div class="pageslist__item_title">{{ page.title }}</div>
        <div class="pageslist__item_body">{{ page.body }}</div>
        <div class="pageslist__item_controls">
          <div
            class="pageslist__item_controls-change pointer"
            @click="openModalChange(index)"
          >
            Изменить
          </div>
        </div>
      </div>
    </div>
  </div>
  <ModalChangePage
    @close="closeModalChange"
    v-if="modalChangePage"
    @closeModal="openModalChange"
    :pageData="pageData"
  />
  <Loader v-if="loader" />
</template>
<script>
import MagnifyIcon from "vue-material-design-icons/Magnify.vue";
import { ref, onMounted, onUpdated } from "vue";
import { usePages } from "@/composition/usePages.js";
import ModalChangePage from "@/components/Modals/ModalChangePage.vue";
import Loader from "@/components/Loader.vue";
export default {
  components: {
    MagnifyIcon,
    ModalChangePage,
    Loader,
  },
  setup(prosp, { emit }) {
    const { getPages, pages } = usePages();
    const loader = ref(false);
    onMounted(() => {
      loader.value = true;
      getPages()
        .then(() => {
          console.log(pages.value);
        })
        .finally(() => {
          loader.value = false;
        });
    });

    const pageData = ref(null);

    const modalChangePage = ref(false);

    const openModalChange = (index) => {
      pageData.value = pages.value[index];
      modalChangePage.value = !modalChangePage.value;
    };

    const closeModalChange = () => {
      modalChangePage.value = false;
      getPages();
    };
    return {
      pages,
      modalChangePage,
      closeModalChange,
      openModalChange,
      pageData,
      loader,
    };
  },
};
</script>

<style lang="scss" src="@/styles/profile/pages/pagesList.scss"></style>
