import axios from "axios";
import { ref } from "vue";
import { useApi } from "@/composition/useApi.js";

export function useUsers() {
  const { api, params } = useApi();
  const users = ref();
  const user = ref();
  const referals = ref();
  const devices = ref();
  const pagination = ref();
  const subscriptions = ref();
  const subscribers = ref();
  const reports = ref();

  const getUsers = (page, sort) => {
    return axios
      .get(api + `/api/v1/cp/users/?page=${page}&sorting=${sort}`, params)
      .then((r) => {
        users.value = r.data.data;
        pagination.value = r.data.meta.paginator;
      });
  };

  const getStatisticUsers = () => {
    return axios.get(api + `/api/v1/cp/statistics/`, params);
  };

  const filterUsers = (page, filter) => {
    return axios
      .get(
        api + `/api/v1/cp/users/?page=${page}${filter}`,
        params
      ) /* &is_active=${is_active}&is_prof=${is_prof}&is_premium=${is_premium} */
      .then((r) => {
        users.value = r.data.data;
        pagination.value = r.data.meta.paginator;
      });
  };

  const searchUsers = (search) => {
    return axios
      .get(api + `/api/v1/cp/users/?search=${search}`, params)
      .then((r) => {
        users.value = r.data.data;
        pagination.value = r.data.meta.paginator;
      });
  };

  const getSelectUser = (id) => {
    return axios.get(api + `/api/v1/cp/users/${id}/`, params).then((r) => {
      user.value = r.data.data;
    });
  };

  const updateUser = (datauser, id) => {
    return axios.put(api + `/api/v1/cp/users/${id}/`, datauser, params);
  };

  const getReferalUser = (id) => {
    return axios
      .get(api + `/api/v1/users/${id}/referrals/`, params)
      .then((r) => {
        referals.value = r.data.data;
      });
  };

  const getDevicesUser = (id) => {
    return axios.get(api + `/api/v1/users/${id}/devices/`, params).then((r) => {
      devices.value = r.data.data;
    });
  };

  const deleteUser = (id) => {
    return axios.delete(api + `/api/v1/cp/users/${id}/`, params);
  };

  const getSubscriptions = (user_id) => {
    return axios
      .get(api + `/api/v1/cp/users/${user_id}/subscriptions/`, params)
      .then((r) => {
        subscriptions.value = r.data.data;
      });
  };
  const getSubscribers = (user_id) => {
    return axios
      .get(api + `/api/v1/cp/users/${user_id}/subscribers/`, params)
      .then((r) => {
        subscribers.value = r.data.data;
      });
  };

  const getReports = (page) => {
    return axios
      .get(api + `/api/v1/cp/users/reports/?page=${page}`, params)
      .then((r) => {
        reports.value = r.data.data;
        pagination.value = r.data.meta.paginator;
      });
  };

  const getReportsHistory = (page) => {
    return axios
      .get(api + `/api/v1/cp/stories/reports/?page=${page}`, params)
      .then((r) => {
        reports.value = r.data.data;
        pagination.value = r.data.meta.paginator;
      });
  };

  const putReports = (report_is, body) => {
    return axios.put(
      api + `/api/v1/cp/users/reports/${report_is}/`,
      body,
      params
    );
  };
  const putReportsHistory = (report_is, body) => {
    return axios.put(
      api + `/api/v1/cp/stories/reports/${report_is}/`,
      body,
      params
    );
  };

  return {
    getUsers,
    filterUsers,
    getSelectUser,
    getReferalUser,
    getDevicesUser,
    updateUser,
    deleteUser,
    searchUsers,
    getSubscribers,
    getSubscriptions,
    getReports,
    putReports,
    getReportsHistory,
    putReportsHistory,
    getStatisticUsers,

    users,
    user,
    referals,
    devices,
    pagination,
    subscriptions,
    subscribers,
    reports,
  };
}
