export function useApi() {
  const api = "https://backend.notalone.su";
  // const api = "https://backend-dev.notalone.su";
  /*    const api = "http://192.168.0.119" */
  const Token = "Bearer" + " " + localStorage.getItem("Token");
  const params = {
    headers: {
      Authorization: Token,
    },
  };
  return {
    api,
    Token,
    params,
  };
}
