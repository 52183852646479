<template>
    <div class="users">
        <div class="users__header">
            <div class="users__search">
                <!-- <input type="text" class="users__search_searchbar" placeholder="Поиск" v-model="search" @input="searchInputUsers"> -->
                <div class="searchBox">
                    <input class="searchInput" type="text" name="" placeholder="Search" v-model="search" @input="searchInputUsers">
                    <button class="searchButton" href="#">
                        <magnify-icon title="search" />
                    </button>
                </div>
                <div class="users__filter">
                    <div class="users__filter-item" @click="filterList = !filterList">
                        Фильтр
                        <img class="users__filter-item_img" src="@/assets/arrow.svg" alt="" :class="{'reflect': filterList}">
                    </div>
                    <div class="users__filter-list" v-if="filterList">
                        <div class="users__filter-column" v-for="(column, index) in filterData" :key="index">
                            <div class="users__filter-column_value" v-for="(value, jindex) in column" :key="jindex" @click="filter(value.filter, value.params, index, jindex)">{{value.name}}
                                <svg class="users__filter-column_img" :class="{'active-img': value.active}" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF"><path d="M0 0h24v24H0z" fill="none"/><path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/></svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="users__table">
                <div class="users__titles">
                    <div class="users__titles_email pointer" @click="updateGetUsers('email')">Email 
                    <img style="width: 10px;" src="@/assets/arrow-green.svg" alt="" :class="{'reflect':sorting.email}">
                    </div>

                    <div class="users__titles_name pointer" @click="updateGetUsers('name')">Имя
                    <img style="width: 10px;" src="@/assets/arrow-green.svg" alt="" :class="{'reflect':sorting.name}">
                    </div>

                    <div class="users__titles_coins pointer" @click="updateGetUsers('coin')">SOLOCOIN
                    <img style="width: 10px;" src="@/assets/arrow-green.svg" alt="" :class="{'reflect':sorting.coin}">
                    </div>

                    <div class="users__titles_created pointer" @click="updateGetUsers('created')">Дата регистрации
                    <img style="width: 10px;" src="@/assets/arrow-green.svg" alt="" :class="{'reflect':sorting.created}">
                    </div>

                    <div class="users__titles_deleted pointer" @click="updateGetUsers('deleted')">Дата удаления
                    <img style="width: 10px;" src="@/assets/arrow-green.svg" alt="" :class="{'reflect':sorting.deleted}">
                    </div>

                    <div class="users__titles_register pointer" @click="updateGetUsers('detected_os')">Система
                    <img style="width: 10px;" src="@/assets/arrow-green.svg" alt="" :class="{'reflect':sorting.detected_os}">
                    </div>

                    <div class="users__titles_control"></div>
                </div>
            </div>
        </div>
        <div class="users__list">
            <div class="users__item" v-for="(user, index) in users" :key="index">
                <div class="users__item_email">{{user.email}}</div>
                <div class="users__item_name">{{user.name}}</div>
                <div class="users__item_coins">{{user.balance}}</div>
                <div class="users__item_created">{{new Date(user.created * 1000).toLocaleString()}}</div>
                <div class="users__item_deleted" v-if="user.deleted">{{new Date(user.deleted * 1000).toLocaleString()}}</div>
                <div class="users__item_deleted" v-else>Не удалён</div>
                <div class="users__item_register" v-if="user.device">{{user.device.detected_os}}</div>
                <div class="users__item_register" v-else>Отсутствует</div>
                <div class="users__item_control">
                    <!-- <div class="users__item_button pointer" @click="activeDropdownMenu(index)">Управление</div> -->
                    <div class="users__item-drop-down_new" @click="openProfileUser(user.id)">Профиль</div>
                    <div class="users__item-drop-down_new" @click="deletes(user.id)" v-if="!user.deleted">Удаление</div>
                    <div class="users__item-drop-down_new-disabled" disabled v-else>Удаление</div>
                </div>
                <!-- <div v-if="dropdown[index].active" class="users__item-drop-down">
                        <div class="users__item-drop-down_item" @click="openProfileUser(user.id)">Профиль</div>
                        <div class="users__item-drop-down_item" @click="deletes(user.id)">Удаление</div>
                </div> -->
            </div>
        </div>
        <div class="users__pagination" v-if="pagination">
            <img src="@/assets/arrow.svg" class="users__pagination_arrow pointer" @click="updateUserPagePrev(pagination.page)"
            v-if="pagination.has_prev">
            <img src="@/assets/arrow.svg" class="users__pagination_arrow pointer" v-else
            :class="{'users__pagination_arrow-disabled': !pagination.has_prev}">

            <div class="user__pagination_page">{{pagination.page}} / {{pagination.total}}</div>

            <img src="@/assets/arrow.svg" class="users__pagination_arrow pointer" @click="updateUserPageNext(pagination.page)"
            v-if="pagination.has_next">
            <img src="@/assets/arrow.svg" class="users__pagination_arrow pointer" v-else
            :class="{'users__pagination_arrow-disabled': !pagination.has_next}">
        </div>
    </div>
    <Loader v-if="loader"/>
</template>
<script>
import {ref, onMounted, onUpdated} from "vue"
import {useUsers} from "@/composition/useUsers.js"
import MagnifyIcon from 'vue-material-design-icons/Magnify.vue';
import Loader from "@/components/Loader.vue"
export default {

    methods: {
        openProfileUser(userId){
            this.$router.push({ name: 'User', params: { id: userId} })
        }
    },
    components: {
        MagnifyIcon,
        Loader,
    },
    setup() {
        const {getUsers, users, pagination, deleteUser, searchUsers, filterUsers} = useUsers()
        const loader = ref(false)
        const dropdown = ref([])
        onMounted(() => {
            loader.value = true
            getUsers(1)
            .then(() => {
                dropdown.value = users.value.map((el) => ({active: false}))
            })
            .finally(() => {
                loader.value = false
            })
        })

        const updateUserPagePrev = (page) => {
            loader.value = true
            getUsers(page-1)
            .then(() => {
                dropdown.value = users.value.map((el) => ({active: false}))
            })
            .finally(() => {
                loader.value = false
            })
        }

        const updateUserPageNext = (page) => {
            loader.value = true
            getUsers(page+1)
            .then(() => {
                dropdown.value = users.value.map((el) => ({active: false}))
            })
            .finally(() => {
                loader.value = false
            })
        }

        const activeDropdownMenu = (index) => {
            dropdown.value.forEach((el, i) => {
                if ( index != i) {
                    el.active = false
                }
            });
            dropdown.value[index].active = !dropdown.value[index].active
        }

        const deletes = (id) => {
            loader.value = true
            deleteUser(id)
            .then(() => {
                getUsers(1)
                .then(() => {
                    dropdown.value = users.value.map((el) => ({active: false}))
                })
                .finally(() => {
                    loader.value = false
                })
            })
        }

        const sorting = ref({
            email: false,
            name: false,
            coin: false,
            created: false,
            deleted: false,
            detected_os: false,
        })

        const updateGetUsers = (sort) => {
            if(sort == "email"){
                if(!sorting.value.email){
                    sorting.value.email = true
                    getUsers(1, "email")
                    .then(() => {
                        dropdown.value = users.value.map((el) => ({active: false}))
                    })
                } else {
                    sorting.value.email = false
                    getUsers(1, "-email")
                    .then(() => {
                        dropdown.value = users.value.map((el) => ({active: false}))
                    })
                }
            }

            if (sort == "name"){
                if(!sorting.value.name){
                    sorting.value.name = true
                    getUsers(1, "name")
                    .then(() => {
                        dropdown.value = users.value.map((el) => ({active: false}))
                    })
                } else {
                    sorting.value.name = false
                    getUsers(1, "-name")
                    .then(() => {
                        dropdown.value = users.value.map((el) => ({active: false}))
                    })
                }
            }

            if (sort == "coin"){
                if(!sorting.value.coin){
                    sorting.value.coin = true
                    getUsers(1, "balance")
                    .then(() => {
                        dropdown.value = users.value.map((el) => ({active: false}))
                    })
                } else {
                    sorting.value.coin = false
                    getUsers(1, "-balance")
                    .then(() => {
                        dropdown.value = users.value.map((el) => ({active: false}))
                    })
                }
            }

            if (sort == "created"){
                if(!sorting.value.created){
                    sorting.value.created = true
                    getUsers(1, "created")
                    .then(() => {
                        dropdown.value = users.value.map((el) => ({active: false}))
                    })
                } else {
                    sorting.value.created = false
                    getUsers(1, "-created")
                    .then(() => {
                        dropdown.value = users.value.map((el) => ({active: false}))
                    })
                }
            }

            if (sort == "deleted"){
                if(!sorting.value.deleted){
                    sorting.value.deleted = true
                    getUsers(1, "deleted")
                    .then(() => {
                        dropdown.value = users.value.map((el) => ({active: false}))
                    })
                } else {
                    sorting.value.deleted = false
                    getUsers(1, "-deleted")
                    .then(() => {
                        dropdown.value = users.value.map((el) => ({active: false}))
                    })
                }
            }
            
            if (sort == "detected_os"){
                if(!sorting.value.detected_os){
                    sorting.value.detected_os = true
                    getUsers(1, "detected_os")
                    .then(() => {
                        dropdown.value = users.value.map((el) => ({active: false}))
                    })
                } else {
                    sorting.value.detected_os = false
                    getUsers(1, "-detected_os")
                    .then(() => {
                        dropdown.value = users.value.map((el) => ({active: false}))
                    })
                }
            }
        }

        const search = ref("")
        const searchInputUsers = () => {
            searchUsers(search.value)
            .then(() => {
                dropdown.value = users.value.map((el) => ({active: false}))
            })
        }

        const filterList = ref(false)

        const filterData = ref([
        [
            {id: 0, name: "Все", active: true, filter: "&is_superuser=", params: null},
            {id: 1, name: "Активный", active: false, filter: "&is_superuser=", params: `true`},
            {id: 2, name: "Не активный", active: false, filter: "&is_superuser=", params: `false`},
        ],
        [
            {id: 3, name: "Все", active: true, filter: "&is_active=", params: null},
            {id: 4, name: "Суперпользователь", active: false, filter: "&is_active=", params: "true"},
            {id: 5, name: "Обычный", active: false, filter: "&is_active=", params: "false"},
        ],
        [
            {id: 6, name: "Все", active: true, filter: "&is_prof=", params: null},
            {id: 7, name: "Проф", active: false, filter: "&is_prof=", params: "true"},
            {id: 8, name: "Не проф", active: false, filter: "&is_prof=", params: "false"},
        ],
        [
            {id: 9, name: "Все", active: true, filter: "&is_premium=", params: null},
            {id: 10, name: "Премиум", active: false, filter: "&is_premium=", params: "true"},
            {id: 11, name: "Не премиум", active: false, filter: "&is_premium=", params: "false"},
        ]])

        const filterArray = ref(["", "", "", "",])

        const filterSuper = ref("&is_superuser=")
        const filterActive = ref("&is_active=")
        const filterProf = ref("&is_prof=")
        const filterPrem = ref("&is_premium=")

        const filter = (filter, params, index, jindex) => {
            console.log(params)
            if(params){
                if(filterSuper.value == filter){
                    filterArray.value[0] = filter+params
                    filterUsers(1, String(filterArray.value).replace(/,/g, ''))
                }

                if(filterActive.value == filter){
                    filterArray.value[1] = filter+params
                    filterUsers(1, String(filterArray.value).replace(/,/g, ''))
                }

                if(filterProf.value == filter){
                    filterArray.value[2] = filter+params
                    filterUsers(1, String(filterArray.value).replace(/,/g, ''))
                }

                if(filterPrem.value == filter){
                    filterArray.value[3] = filter+params
                    filterUsers(1, String(filterArray.value).replace(/,/g, ''))
                }
            } else {
                if(filterSuper.value == filter){
                    filterArray.value[0] = ""
                    filterUsers(1, String(filterArray.value).replace(/,/g, ''))
                }

                if(filterActive.value == filter){
                    filterArray.value[1] = ""
                    filterUsers(1, String(filterArray.value).replace(/,/g, ''))
                }

                if(filterProf.value == filter){
                    filterArray.value[2] = ""
                    filterUsers(1, String(filterArray.value).replace(/,/g, ''))
                }

                if(filterPrem.value == filter){
                    filterArray.value[3] = ""
                    filterUsers(1, String(filterArray.value).replace(/,/g, ''))
                }
            }

            filterData.value[index].forEach(element => {
                element.active = false
                /* console.log(element) */
            })
            if(!filterData.value[index][jindex].active) {
                filterData.value[index][jindex].active = true
            }
            /* console.log(String(filterArray.value).replace(/,/g, '')) */
        }

        const dataFilterSorting = ref([])
        
        const select = (index, jindex) => {
            filterData.value[index].forEach(element => {
                element.active = false
                /* console.log(element) */
            })
            if(!filterData.value[index][jindex].active) {
                filterData.value[index][jindex].active = true

                if(!filterData.value[index][jindex].params != ""){
                    dataFilterSorting.value.push(filterData.value[index][jindex].filter + filterData.value[index][jindex].params)
                        console.log(dataFilterSorting.value.join(''))
                        getUsers(1, "", dataFilterSorting.value.join(''))
                        .then(() => {
                            dropdown.value = users.value.map((el) => ({active: false}))
                        })
                } /* else {
                    dataFilterSorting.value.push(filterData.value[index][jindex].filter + filterData.value[index][jindex].params)
                        console.log(dataFilterSorting.value.join(''))
                        getUsers(1, "", dataFilterSorting.value.join(''))
                        .then(() => {
                            dropdown.value = users.value.map((el) => ({active: false}))
                        })
                } */
            }

        }
        
        return{
            users,
            dropdown,
            pagination,

            activeDropdownMenu,

            updateUserPagePrev,
            updateUserPageNext,

            deletes,

            updateGetUsers,

            sorting,

            search,
            searchInputUsers,

            filterList,
            filterData,
            select,
            dataFilterSorting,

            filter,
            filterArray,

            loader,
        }
    }
}
</script>

<style lang="scss" src="@/styles/profile/users/users.scss"></style>