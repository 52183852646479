<template>
<div class="login">
    <div class="login__wrapper">
        <div class="login__aut">
            <div class="login__form">
                <div class="login__form_title">Добро пожаловать</div>
                <input type="text" class="login__form_email" v-model="form.username" placeholder="Email">
                <input type="text" class="login__form_password" v-model="form.password" placeholder="Пароль">
                <div class="login__forgot">
                    <a class="login__forgot_name pointer" @click="routerPush('Recover')">Забыли пароль ?</a>
                </div>
                <div class="login__buttons">
                    <div class="login__buttons_login" @click="login">Войти</div>
                </div>
            </div>
        </div>
        <div class="login__logo">
            <div class="login__logo_line">1</div>
            <img src="@/assets/logo.svg" alt="" class="login__logo_img">
        </div>
    </div>
</div>
</template>

<script>
import {
    ref, onMounted
} from 'vue';
import { useRouter } from "vue-router"
import {
    useLogin
} from '@/composition/useLogin.js';

export default ({
    setup() {
        const router = useRouter()
        const routerPush = (name) => {
            router.push({
                name: name
            })
        }
        const {
            loginUser
        } = useLogin()

        const form = ref({
            username: "",
            password: "",
        })

        const login = () => {
            loginUser(form.value)
                .then(() => {
                    routerPush('Profile')
                })
                .catch(() => {
                    alert("Bad")
                })
        }

        onMounted(() => {
            document.addEventListener('keydown', function(e) {
                if (e.code == 'Enter') {
                    login()
                }
            });
        })
        return {
            routerPush,

            form,
            login,
        }
    }
});
</script>

<style lang="scss" src="@/styles/autorization/login.scss"></style>
