<template>
  <div class="groups">
    <div class="groups__header">
      <div class="groups__search">
        <div class="searchBox">
          <input class="searchInput" type="text" name="" placeholder="Search" />
          <button class="searchButton" href="#">
            <magnify-icon title="search" />
          </button>
        </div>
      </div>
      <div class="groups__addeted">
        <div class="groups__addeted_add pointer" @click="openModalCreateGroup">
          Добавить
        </div>
      </div>
    </div>
    <div class="groups__table">
      <div class="groups__titles">
        <div class="groups__titles_title">Название</div>
        <div class="groups__titles_teacher">Ведущий</div>
        <div class="groups__titles_img">Изображение</div>
        <div class="groups__titles_time">Дительность</div>
        <div class="groups__titles_limit">Лимит человек</div>
        <div class="groups__titles_limit">Записалось</div>
        <div class="groups__titles_theme">Тема</div>
        <div class="groups__titles_control"></div>
      </div>
    </div>
    <div class="groups__list">
      <div class="groups__item" v-for="(group, index) in groups" :key="index">

        <div class="groups__item_title">{{ group.title }}</div>
        <div class="groups__item_teacher">{{ group.teacher }}</div>
        <div class="groups__item_img">
          <img
            class="groups__item_img-img"
            style="width: 50px"
            :src="group.cover"
            alt=""
          />
        </div>
        <div class="groups__item_time">{{ group.lesson_duration }}</div>
        <div class="groups__item_limit" v-if="group.members_limit">
          {{ group.members_limit }}
        </div>
        <div class="groups__item_limit" v-else>Без ограничений</div>
        <div class="groups__item_limit">
          {{ group.members_count }}
        </div>
        <div class="groups__item_theme" v-if="group.topic">
          {{ group.topic.name }}
        </div>
        <div class="groups__item_theme" v-else>Тема отсутсвует</div>
       
        <div class="groups__item_control">
          <div class="groups__item-drop-down_new" @click="route(group.id)">Занятия</div>
          <div
            class="groups__item-drop-down_new"
            @click="openModalChangeGroup(group, index)"
          >
            Изменить
          </div>
          <div class="groups__item-drop-down_new" @click="deletes(group.id, index)">
            Удаление 
          </div>
          
          <!-- <div class="groups__item_button pointer" @click="activeDropdownMenu(index)">Управление</div> -->
          <!-- <div v-if="dropdown[index].active" class="groups__item-drop-down">
                        <div class="groups__item-drop-down_item" @click="route(group.id)">Занятия</div>
                        <div class="groups__item-drop-down_item" @click="openModalChangeGroup(group, index)">Изменить</div>
                        <div class="groups__item-drop-down_item" @click="deletes(group.id, index)">Удаление</div>
                    </div> -->
        </div>
      </div>
    </div>

    <div class="users__pagination" v-if="pagination">
      <img
        src="@/assets/arrow.svg"
        class="users__pagination_arrow pointer"
        @click="updateUserPagePrev(pagination.page)"
        v-if="pagination.has_prev"
      />
      <img
        src="@/assets/arrow.svg"
        class="users__pagination_arrow pointer"
        v-else
        :class="{ 'users__pagination_arrow-disabled': !pagination.has_prev }"
      />

      <div class="user__pagination_page">
        {{ pagination.page }} / {{ pagination.total }}
      </div>

      <img
        src="@/assets/arrow.svg"
        class="users__pagination_arrow pointer"
        @click="updateUserPageNext(pagination.page)"
        v-if="pagination.has_next"
      />
      <img
        src="@/assets/arrow.svg"
        class="users__pagination_arrow pointer"
        v-else
        :class="{ 'users__pagination_arrow-disabled': !pagination.has_next }"
      />
    </div>
  </div>
  <ModalCreateGroup v-if="modalCreateGroup" @close="closeModalCreateGroup" />
  <ModalChangeGroup
    v-if="modalChangeGroup"
    @close="closeModalChangeGroup"
    :group="groupData"
  />
  <Loader v-if="loader" />
</template>

<script>
import { ref, onMounted } from "vue";
import { useGroups } from "@/composition/useGroups.js";

import ModalCreateGroup from "@/components/Modals/ModalCreateGroup.vue";
import ModalChangeGroup from "@/components/Modals/ModalChangeGroup.vue";
import MagnifyIcon from "vue-material-design-icons/Magnify.vue";
import Loader from "@/components/Loader.vue";
import router from "@/router";
export default {
  components: {
    ModalCreateGroup,
    ModalChangeGroup,
    MagnifyIcon,
    Loader,
  },
  /* methods: {
    route(id) {
      this.$router.push({ name: "Lesons", params: { id: id } });
    },
  }, */
  setup() {
    const route = (id) => {
      router.push({path: `/profile/webinars/lessons/${id}`})
    }
    const { getGroups, groups, deleteGroups, pagination } = useGroups();
    const loader = ref(false);
    const dropdown = ref([]);

    const updateUserPagePrev = (page) => {
      loader.value = true;
      getGroups(page - 1).finally(() => {
        loader.value = false;
      });
    };

    const updateUserPageNext = (page) => {
      loader.value = true;
      getGroups(page + 1).finally(() => {
        loader.value = false;
      });
    };

    onMounted(() => {
      loader.value = true;
      getGroups(1)
        .then(() => {
          dropdown.value = groups.value.map((el) => ({ active: false }));
        })
        .finally(() => {
          loader.value = false;
        });
    });

    const activeDropdownMenu = (index) => {
      dropdown.value.forEach((el, i) => {
        if (index != i) {
          el.active = false;
        }
      });
      dropdown.value[index].active = !dropdown.value[index].active;
    };

    const deletes = (id, index) => {
      loader.value = true;
      deleteGroups(id)
        .then(() => {
          getGroups(1).then(() => {
            dropdown.value = groups.value.map((el) => ({ active: false }));
          });
        })
        .finally(() => {
          loader.value = false;
        });
      dropdown.value[index].active = false;
    };

    const modalCreateGroup = ref(false);
    const openModalCreateGroup = () => {
      modalCreateGroup.value = true;
    };
    const closeModalCreateGroup = () => {
      modalCreateGroup.value = false;
      loader.value = true;
      getGroups(1)
        .then(() => {
          dropdown.value = groups.value.map((el) => ({ active: false }));
        })
        .finally(() => {
          loader.value = false;
        });
    };

    const modalChangeGroup = ref(false);
    const groupData = ref(null);
    const openModalChangeGroup = (group, index) => {
      modalChangeGroup.value = true;
      groupData.value = group;
      dropdown.value[index].active = false;
    };
    const closeModalChangeGroup = () => {
      modalChangeGroup.value = false;
      loader.value = true;
      getGroups(1)
        .then(() => {
          dropdown.value = groups.value.map((el) => ({ active: false }));
        })
        .finally(() => {
          loader.value = false;
        });
    };

    return {
      groups,
      dropdown,
      activeDropdownMenu,
      deletes,

      modalCreateGroup,
      openModalCreateGroup,
      closeModalCreateGroup,

      modalChangeGroup,
      openModalChangeGroup,
      closeModalChangeGroup,

      groupData,

      loader,

      pagination,
      updateUserPagePrev,
      updateUserPageNext,
      route,
    };
  },
};
</script>

<style lang="scss" src="@/styles/profile/webinars/groups.scss"></style>
