<template>
  <div class="modalChangePage" @click.self="close">
    <div class="modalChangePage__form" v-if="data">
      <div class="modalChangePage__header">
        <div class="modalChangePage__header_title">Изменение страницы</div>
        <svg
          @click="close"
          class="modalChangePage__header_close pointer"
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 0 24 24"
          width="24px"
          fill="#333"
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path
            d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
          />
        </svg>
      </div>
      <div class="modalChangePage__main">
        <input
          class="modalChangePage__main_title"
          placeholder="Заголовок"
          v-model="data.title"
        />
        <textarea
          name=""
          id=""
          cols="30"
          rows="10"
          class="modalChangePage__main_textarea"
          placeholder="Текст"
          v-model="data.body"
        ></textarea>

        <input
          v-if="
            data.tech_name == 'terms' ||
            data.tech_name == 'policy' ||
            data.tech_name == 'solocoin_cond' ||
            data.tech_name == 'smt_long'
          "
          ref="file"
          type="file"
        />
      </div>
      <div class="modalChangePage__buttons">
        <div class="modalChangePage__buttons_cancel pointer" @click="close">
          Отмена
        </div>
        <div
          class="modalChangePage__buttons_save pointer"
          @click="updatePage"
          v-if="
            data.tech_name != 'terms' &&
            data.tech_name != 'policy' &&
            data.tech_name != 'solocoin_cond' &&
            data.tech_name != 'smt_long'
          "
        >
          Изменить
        </div>

        <div
          class="modalChangePage__buttons_save pointer"
          @click="updateDocument(data.tech_name)"
          v-else
        >
          Изменить
        </div>
      </div>
    </div>
  </div>
  <Loader v-if="loader" />
</template>

<script>
import { ref, onMounted } from "vue";
import { usePages } from "@/composition/usePages.js";
import Loader from "@/components/Loader.vue";
import axios from "axios";
import {useApi} from "@/composition/useApi.js"

const { api, params } = useApi();
export default {
  props: {
    pageData: Object,
  },
  components: {
    Loader,
  },
  data() {
    return {
      file: null,
    };
  },
  methods: {
    updateDocument(name_doc) {
      this.file = this.$refs.file.files[0];
      const formData = new FormData();
      formData.append("file", this.file);
      this.loader = true;
      if (name_doc == "policy") {
        return axios
          .put(
            api + "/api/v1/cp/pages/pdf/policy.docx/",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                'Authorization': "Bearer" + " " + localStorage.getItem("Token"), //prettier-ignore
              },
            }
          )
          .finally(() => {
            this.loader = false;
            return axios.put(
              `${api}/api/v1/cp/pages/${this.data.id}/`,
              {
                tech_name: this.data.tech_name,
                title: this.data.title,
                body: this.data.body,
              },
              {
                headers: {
                  'Authorization': "Bearer" + " " + localStorage.getItem("Token"), //prettier-ignore
                },
              }
            );
          });
      }

      if (name_doc == "terms") {
        return axios
          .put(
            api + "/api/v1/cp/pages/pdf/terms.docx/",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                'Authorization': "Bearer" + " " + localStorage.getItem("Token"), //prettier-ignore
              },
            }
          )
          .finally(() => {
            this.loader = false;
            return axios.put(
              `${api}/api/v1/cp/pages/${this.data.id}/`,
              {
                tech_name: this.data.tech_name,
                title: this.data.title,
                body: this.data.body,
              },
              {
                headers: {
                  'Authorization': "Bearer" + " " + localStorage.getItem("Token"), //prettier-ignore
                },
              }
            );
          });
      }

      //Раскоментировать когда необходимо добавить документ прос солокоины и "Подробнее" (ссылки узнать у Владимира с названием файлов)
      if (name_doc == "solocoin_cond") {
        return axios
          .put(
            api + "/api/v1/cp/pages/pdf/solocoin.pdf/",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                'Authorization': "Bearer" + " " + localStorage.getItem("Token"), //prettier-ignore
              },
            }
          )
          .finally(() => {
            this.loader = false;
            return axios.put(
              `${api}/api/v1/cp/pages/${this.data.id}/`,
              {
                tech_name: this.data.tech_name,
                title: this.data.title,
                body: this.data.body,
              },
              {
                headers: {
                  'Authorization': "Bearer" + " " + localStorage.getItem("Token"), //prettier-ignore
                },
              }
            );
          });
      }
      if (name_doc == "smt_long") {
        return axios
          .put(
            api + "/api/v1/cp/pages/pdf/more.docx/",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                'Authorization': "Bearer" + " " + localStorage.getItem("Token"), //prettier-ignore
              },
            }
          )
          .finally(() => {
            this.loader = false;
            return axios.put(
              `${api}/api/v1/cp/pages/${this.data.id}/`,
              {
                tech_name: this.data.tech_name,
                title: this.data.title,
                body: this.data.body,
              },
              {
                headers: {
                  'Authorization': "Bearer" + " " + localStorage.getItem("Token"), //prettier-ignore
                },
              }
            );
          });
      }
    },
  },
  setup(props, { emit }) {
    const { changePage } = usePages();
    const close = () => {
      emit("closeModal");
    };
    const data = ref(null);
    const loader = ref(false);
    onMounted(() => {
      data.value = props.pageData;
    });
    const updatePage = () => {
      loader.value = true;
      changePage(
        {
          tech_name: data.value.tech_name,
          title: data.value.title,
          body: data.value.body,
        },
        data.value.id
      ).finally(() => {
        loader.value = false;
      });
    };
    return {
      close,
      updatePage,
      data,
      loader,
    };
  },
};
</script>

<style lang="scss" scoped>
.modalChangePage {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  &__form {
    background: #ffffff;
    padding: 20px;
    width: 500px;
    border-radius: 8px;
    border: 1px solid #333;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &_title {
      color: #333;
      font-size: 20px;
    }

    &_close {
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
    margin-top: 10px;

    & input {
      padding: 10px;
      margin-top: 10px;
    }

    &_topic {
      margin-top: 10px;
      width: 100%;

      .dropdown-input {
        padding: 10px;
        max-width: 500px;
        width: 500px;
      }
    }

    &_img {
      margin-top: 10px;
      border: 1px solid #333;
      width: 100%;
      height: 350px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &_textarea {
      margin-top: 10px;
      height: 300px;
      padding: 10px;
      font-family: Arial, Helvetica, sans-serif;
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    &_cancel {
      padding: 10px 40px;
      background: #8f4aeb;
      color: #ffffff;
      border-radius: 8px;
    }

    &_save {
      padding: 10px 40px;
      background: #8f4aeb;
      color: #ffffff;
      border-radius: 8px;
    }
  }
}
</style>
