<template>
<div class="recover">
    <div class="recover__wrapper">
        <div class="recover__aut">
            <div class="recover__form">
                <div class="recover__form_title">Сброс пароля</div>
                <input type="text" class="recover__form_email" v-model="form.email" placeholder="Email">
                <div class="recover__forgot">
                    <a class="recover__forgot_name pointer" @click="routerPush('Login')">Войти</a>
                </div>
                <div class="recover__buttons">
                    <div class="recover__buttons_login" @click="recover('Login')">Сбросить пароль</div>
                </div>
            </div>
        </div>
        <div class="recover__logo">
            <div class="recover__logo_line">1</div>
            <img src="@/assets/logo.svg" alt="" class="recover__logo_img">
        </div>
    </div>
</div>
</template>

<script>
import {
    ref
} from 'vue';
import { useRouter } from "vue-router"
import {
    useLogin
} from '@/composition/useLogin.js';

export default ({
    setup() {
        const router = useRouter()
        const routerPush = (name) => {
            router.push({
                name: name
            })
        }
        const {
            recoveruser
        } = useLogin()

        const form = ref({
            email: "",
        })

        const recover = (name) => {
            recoveruser(form.value)
                .then(() => {
                    routerPush(name)
                })
                .catch(() => {
                    alert("Bad")
                })
        }
        return {
            routerPush,

            form,
            recover,
        }
    }
});
</script>

<style lang="scss" src="@/styles/autorization/recover.scss"></style>
