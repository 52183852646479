<template>
  <div class="modalCreateGroup" @click.self="close">
    <div class="modalCreateGroup__form">
      <div class="modalCreateGroup__header">
        <div class="modalCreateGroup__header_title">Изменение группы</div>
        <svg
          @click="close"
          class="modalCreateGroup__header_close pointer"
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 0 24 24"
          width="24px"
          fill="#333"
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path
            d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
          />
        </svg>
      </div>
      <div class="modalCreateGroup__main">
        <input
          class="modalCreateGroup__main_title"
          placeholder="Название"
          v-model="dataGroup.title"
        />
        <input
          class="modalCreateGroup__main_teacher"
          placeholder="Ведущий"
          v-model="dataGroup.teacher"
        />
        <VImageInput v-model="image1" removable> </VImageInput>
        <input
          class="modalCreateGroup__main_lesson-duration"
          placeholder="Длительность"
          v-model="dataGroup.lesson_duration"
        />
        <input
          class="modalCreateGroup__main_members-limit"
          placeholder="Лимит человек"
          v-model="dataGroup.members_limit"
        />
        <!-- <input class="modalCreateGroup__main_topic" placeholder="Тема" v-model="dataGroup.topic.name"> -->
        <Dropdown
          class="modalCreateGroup__main_topic"
          :options="topics"
          v-on:selected="validateSelection"
          v-on:filter="getDropdownValues"
          :disabled="false"
          name="zipcode"
          :maxItem="10"
          :placeholder="placeholderTopic"
        />
        <input
          class="modalCreateGroup__main_members-limit"
          placeholder="Ссылка"
          v-model="dataGroup.link"
        />
      </div>
      <div class="modalCreateGroup__buttons">
        <div class="modalCreateGroup__buttons_cancel pointer" @click="close">Отмена</div>
        <div class="modalCreateGroup__buttons_save pointer" @click="create(dataGroup.id)">
          Изменить
        </div>
      </div>
    </div>
  </div>
  <Loader v-if="loader" />
</template>

<script>
import { ref } from "vue";
import { useGroups } from "@/composition/useGroups.js";
import axios from "axios";
import Dropdown from "@/components/DropdownModal.vue";
import PictureInput from "vue-picture-input";
import VImageInput from "@/components/VImageInput";
import Loader from "@/components/Loader.vue";
import {useApi} from "@/composition/useApi.js"

const { api, params } = useApi();
export default {
  components: {
    PictureInput,
    Dropdown,
    VImageInput,
    Loader,
  },

  data() {
    return {
      loader: true,
      image1: null,
      placeholderTopic: "Тема123",
      topics: [],
      topicId: null,
      dataGroup: {
        title: "",
        teacher: "",
        lesson_duration: "",
        topic: {
          name: "",
          id: 0,
        },
        link: "",
        members_limit: null,
      },
    };
  },

  props: {
    group: Object,
  },

  created() {
    /*             this.dataGroup.topic = this.$props.group.topic.name */
    /* console.log(this.$props.group) */
  },

  mounted() {
    this.dataGroup = this.$props.group;
    this.topicId = this.$props.group.topic.id;
    this.placeholderTopic = this.$props.group.topic.name;
    this.image1 = this.$props.group.cover;
    return axios
      .get(`${api}/api/v1/cp/topics/`, params)
      .then((r) => {
        this.topics = r.data.data;
        console.log(r);
        console.log(this.topics);
      })
      .finally(() => {
        this.loader = false;
      });
  },

  methods: {
    close() {
      this.$emit("close");
    },

    onChange(image) {
      this.file = this.$refs.pictureInput.file;
      console.log("New picture selected!");
      if (image) {
        console.log("Picture loaded.");
        this.image = image;
      } else {
        console.log("FileReader API not supported: use the <form>, Luke!");
      }
    },

    sendDataImg(id_group) {
      this.loader = true;
      const formData = new FormData();
      formData.append("new_cover", this.image1);
      const config = {
        headers: {
          ...params.headers,
          "content-type": "multipart/form-data",
        },
      };
      return axios
        .post(
          `${api}/api/v1/cp/groups/${id_group}/cover/`,
          formData,
          config
        )
        .then((r) => {})
        .finally(() => {
          this.loader = false;
        });
    },

    validateSelection(data) {
      this.topicId = data.id;
    },

    create(id) {
      this.loader = true;
      return axios
        .put(
          `${api}/api/v1/cp/groups/${id}/`,
          {
            title: this.dataGroup.title,
            teacher: this.dataGroup.teacher,
            lesson_duration: this.dataGroup.lesson_duration,
            topic: this.topicId,
            members_limit: this.dataGroup.members_limit,
          },
          params
        )
        .then((r) => {
          this.sendDataImg(r.data.data.id);
          this.$emit("close");
        })
        .finally(() => {
          this.$emit("close");
          this.loader = false;
        });
    },

    onChangeFileUpload() {
      this.file = this.$refs.file.files[0];
    },
  },
};
</script>

<style lang="scss" scoped>
.modalCreateGroup {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  &__form {
    background: #ffffff;
    padding: 20px;
    width: 500px;
    border-radius: 8px;
    border: 1px solid #333;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &_title {
      color: #333;
      font-size: 20px;
    }
    &_close {
    }
  }
  &__main {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    & input {
      padding: 10px;
      margin-top: 10px;
    }
    &_topic {
      margin-top: 10px;
      width: 100%;
      margin-bottom: 10px;
      .dropdown-input {
        padding: 10px;
        max-width: 500px;
        width: 500px;
      }
    }
    &_img {
      margin-top: 10px;
      border: 1px solid #333;
      width: 100%;
      height: 350px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &__buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    &_cancel {
      padding: 10px 40px;
      background: #8f4aeb;
      color: #ffffff;
      border-radius: 8px;
    }
    &_save {
      padding: 10px 40px;
      background: #8f4aeb;
      color: #ffffff;
      border-radius: 8px;
    }
  }
}
</style>
