
import axios from 'axios'
import {ref} from "vue"
import {useApi} from "@/composition/useApi.js"

export function useTopics() {

    const { api, params } = useApi()
    const topics = ref()
    const getTopics = () => {
       return axios.get(api + `/api/v1/cp/topics/`, params)
       .then((r) => {
        topics.value = r.data.data
       })
    }
    const deleteTopics = (id) => {
        return axios.delete(api + `/api/v1/cp/topics/${id}/`, params)
    }

    const createTopics = (body) => {
        return axios.post(api + `/api/v1/cp/topics/`, body, params)
    }

    const changeTopic = (body, id) => {
        return axios.put(api + `/api/v1/cp/topics/${id}/`, body, params)
    }

    return {
        getTopics,
        deleteTopics,
        topics,
        createTopics,
        changeTopic,
    }
}