<template>
    <div class="reports">
        <div class="reports__header">
            <div class="reports__search">
                <div class="searchBox">
                    <input class="searchInput" type="text" name="" placeholder="Search" v-model="search" @input="searchInputUsers">
                    <button class="searchButton" href="#">
                        <magnify-icon title="search" />
                    </button>
                </div>
            </div>
            <div class="reports__table">
                <div class="reports__titles">
                    <div class="reports__titles_name pointer">Имя</div>
                    <div class="reports__titles_email pointer">Email</div>

                    <div class="reports__titles_name pointer">Имя</div>
                    <div class="reports__titles_email pointer">Email</div>

                    <div class="reports__titles_reason pointer">Причина</div>
                    <div class="reports__titles_description pointer">Описание проблемы</div>

                    <div class="reports__titles_status">Статус</div>
                    <div class="reports__titles_control"></div>
                </div>
            </div>
        </div>
        <div class="reports__list">
            <div class="reports__item" v-for="(user, index) in reports" :key="index">
                <div class="reports__item_name">{{user.object.name}}</div>
                <div class="reports__item_email">{{user.object.email}}</div>
                
                <div class="reports__item_name">{{user.subject.name}}</div>
                <div class="reports__item_email">{{user.subject.email}}</div>

                <div class="reports__item_reason">{{meReasons[index].reasonName}}</div>
                <div class="reports__item_description">{{user.additional_text}}</div>
                
                <div class="reports__item_status" v-if="user.is_satisfy == false">
                    <p class="reports__item_control-red">Не одобрено</p>
                </div>
                <div class="reports__item_status" v-else-if="user.is_satisfy == true">
                    <p class="reports__item_control-green">Одобрено</p>
                </div>
                <div class="reports__item_status" v-else>
                    <p class="reports__item_control-green">На рассмотрении</p>
                </div>

                <div class="reports__item_control">
                    <div class="reports__item_button pointer" @click="changeReport(user.id, true)">
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/></svg></div>
                    <div class="reports__item_button pointer" @click="changeReport(user.id, false)">
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 13H5v-2h14v2z"/></svg></div>
                </div>
            </div>
        </div>
        <!-- <div class="reports__pagination" v-if="pagination">
            <img src="@/assets/arrow.svg" class="reports__pagination_arrow pointer" @click="updateUserPagePrev(pagination.page)"
            v-if="pagination.has_prev">
            <img src="@/assets/arrow.svg" class="reports__pagination_arrow pointer" v-else
            :class="{'reports__pagination_arrow-disabled': !pagination.has_prev}">

            <div class="reports__pagination_page">{{pagination.page}} / {{pagination.total}}</div>

            <img src="@/assets/arrow.svg" class="reports__pagination_arrow pointer" @click="updateUserPageNext(pagination.page)"
            v-if="pagination.has_next">
            <img src="@/assets/arrow.svg" class="reports__pagination_arrow pointer" v-else
            :class="{'reports__pagination_arrow-disabled': !pagination.has_next}">
        </div> -->
        <div class="reports__pagination" v-if="pagination">
            <template v-if="pagination">
                <img src="@/assets/arrow.svg" class="reports__pagination_arrow pointer" @click="updateUserPagePrev(pagination.page)"
                v-if="pagination.has_prev">
                <img src="@/assets/arrow.svg" class="reports__pagination_arrow pointer" v-else
                :class="{'reports__pagination_arrow-disabled': !pagination.has_prev}">
                <div class="reports__pagination_page">{{pagination.page}} / {{pagination.total}}</div>
                <img src="@/assets/arrow.svg" class="reports__pagination_arrow pointer" @click="updateUserPageNext(pagination.page)"
                v-if="pagination.has_next">
                <img src="@/assets/arrow.svg" class="reports__pagination_arrow pointer" v-else
                :class="{'reports__pagination_arrow-disabled': !pagination.has_next}">
            </template>
            <template v-else>
                <img src="@/assets/arrow.svg" class="reports__pagination_arrow pointer reports__pagination_arrow-disabled">
                <div class="reports__pagination_page">0 / 0</div>
                <img src="@/assets/arrow.svg" class="reports__pagination_arrow pointer reports__pagination_arrow-disabled">
            </template>
        </div>
    </div>
    <Loader v-if="loader"/>
</template>
<script>
import {ref, onMounted, onUpdated} from "vue"
import {useUsers} from "@/composition/useUsers.js"
import MagnifyIcon from 'vue-material-design-icons/Magnify.vue';
import Loader from "@/components/Loader.vue"
export default {
    components: {
        MagnifyIcon,
        Loader,
    },
    methods: {
        openProfileUser(userId){
            this.$router.push({ name: 'User', params: { id: userId} })
        }
    },

    setup() {
        const {getReportsHistory, reports, putReportsHistory, pagination} = useUsers()

        const dropdown = ref([])
        const meReasons = ref([])
        const loader = ref(false)
        onMounted(() => {
            loader.value = true
            getReportsHistory(1)
            .then(() => {
                dropdown.value = reports.value.map((el) => ({active: false}))
                meReasons.value = [...reports.value]
                reports.value.forEach((report, index) => {
                    reasons.value.forEach((reason) => {
                        if(report.reason == reason.id){
                            meReasons.value[index].reasonName = reason.name
                        }
                    })
                })

                console.log(meReasons.value)
            })
            .finally(() => {
                loader.value = false
            })
        })

        const updateData = (page) => {
            loader.value = true
            getReportsHistory(page)
            .then(() => {
                dropdown.value = reports.value.map((el) => ({active: false}))
                meReasons.value = [...reports.value]
                reports.value.forEach((report, index) => {
                    reasons.value.forEach((reason) => {
                        if(report.reason == reason.id){
                            meReasons.value[index].reasonName = reason.name
                        }
                    })
                })

                console.log(meReasons.value)
            })
            .finally(() => {
                loader.value = false
            })
        }

        const updateUserPagePrev = (page) => {
            loader.value = true
            getReportsHistory(page-1)
            .then(() => {
                dropdown.value = reports.value.map((el) => ({active: false}))
            })
            .finally(() => {
                loader.value = false
            })
        }

        const updateUserPageNext = (page) => {
            loader.value = true
            getReportsHistory(page+1)
            .then(() => {
                dropdown.value = reports.value.map((el) => ({active: false}))
            })
            .finally(() => {
                loader.value = false
            })
        }

        const activeDropdownMenu = (index) => {
            dropdown.value.forEach((el, i) => {
                if ( index != i) {
                    el.active = false
                }
            });
            dropdown.value[index].active = !dropdown.value[index].active
        }

        const deletes = (id) => {
            loader.value = true
            deleteUser(id)
            .finally(() => {
                loader.value = false
            })
        }

        
        const search = ref("")
        const searchInputUsers = () => {
            searchUsers(search.value)
            .then(() => {
                dropdown.value = users.value.map((el) => ({active: false}))
            })
        }

        const reasons = ref([
            {id: 0, name: "Спам",},
            {id: 1, name: "Изображения обнаженного тела или действий сексуального характера",},
            {id: 2, name: "Враждебные высказывания или символы Враждебные высказывания или символы",},
            {id: 3, name: "Насилие или опасные организации",},
            {id: 4, name: "Травля или преследования",},
            {id: 5, name: "Продажа незаконных товаров или товаров, подлежащих правовому регулированию",},
            {id: 6, name: "Нарушение прав на интеллектуальную собственность",},
            {id: 7, name: "Самоубийство или нанесение себе увечий",},
            {id: 8, name: "Расстройство пищевого поведения",},
            {id: 9, name: "Мошенничество или обман",},
            {id: 10, name: "Ложная информация",},
        ])

        const changeReport = (report_id, change) => {
            loader.value = true
            if(change){
                putReportsHistory(report_id, {is_satisfy: change})
                .then(() => {
                    updateData(1)
                })
                .finally(() => {
                    loader.value = false
                })
            } else {
                putReportsHistory(report_id, {is_satisfy: change})
                .then(() => {
                    updateData(1)
                })
                .finally(() => {
                    loader.value = false
                })
            }
        }
        
        return{
            reports,
            dropdown,
            meReasons,
            updateData,
            changeReport,
            pagination,

            activeDropdownMenu,

            updateUserPagePrev,
            updateUserPageNext,

            deletes,

            search,
            searchInputUsers,

            reasons,

            loader
        }
    }
}
</script>

<style lang="scss" src="@/styles/profile/users/reports.scss"></style>