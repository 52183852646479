<template>
<div class="user-referals-data__header">
    <div class="user-referals-data__titles">
        <div class="user-referals-data__titles_avatar">Аватар</div>
        <div class="user-referals-data__titles_name">Имя</div>
        <div class="user-referals-data__titles_created">Дата регистрации</div>
    </div>
    <div class="user-referals-data__list">
        <div class="user-referals-data__item" v-for="(referal, index) in referals" :key="index">
            <img style="width: 50px; height: 50px; border: 1px solid #333; border-radius: 100px;
            " :src="referal.avatar" v-if="referal.avatar">
            <img style="width: 30px; height: 30px; border: 1px solid #333; border-radius: 100px;
            " src="@/assets/user.png" v-else>
            <div class="user-referals-data__item_name">{{referal.name}}</div>
            <div class="user-referals-data__item_created">{{new Date(referal.created * 1000).toLocaleString()}}</div>
        </div>
    </div>
</div>
<Loader v-if="loader"/>
</template>

<script>
import {
    ref,
    onMounted,
    onUpdated
} from "vue"
import {
    useUsers
} from "@/composition/useUsers.js"
import {
    useRoute
} from "vue-router"
import Loader from "@/components/Loader.vue"
export default {
    components:{
        Loader,
    },
    setup() {
        const {
            getReferalUser,
            referals
        } = useUsers()
        const loader = ref(true)
        const route = useRoute()

        onMounted(() => {
            let user_id = route.params.id
            loader.value = true
            getReferalUser(user_id)
            .then(() => {
                console.log(referals.value)
            })
            .finally(() => {
                loader.value = false
            })
        })
        return {
            referals,
            loader,
        }
    }
}
</script>

<style lang="scss" src="@/styles/profile/users/userReferalsData.scss"></style>
