<template>
    <div class="profile">
        <HeaderProfile @showMenuProfile="showMenuProfile" />
        <div style="display: flex;">
            <MenuProfile class="profile__menu-active" :class="{'profile__menu-deactive': !menuProfile}"/>
            <div class="profile__router">
                <div class="profile__router-name">{{nameRoute}}</div>
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
import HeaderProfile from "@/components/Profile/HeaderProfile.vue"
import MenuProfile from "@/components/Profile/MenuProfile.vue"
export default {

    components:{
        HeaderProfile,
        MenuProfile,
    },

    data() {
        return {
            nameRoute: "Name",
            menuProfile: true,
        }
    },

    mounted() {
        this.nameRoute = this.$route.meta.link
    },

    updated() {
        this.nameRoute = this.$route.meta.link
    },

    methods: {
        showMenuProfile(){
            this.menuProfile = !this.menuProfile
        }
    },
}
</script>

<style lang="scss" src="@/styles/profile/profile.scss"></style>