<template>
<div class="user-referals-data__header">
    <div class="user-referals-data__titles">
        <div class="user-referals-data__titles_avatar">Способ получения</div>
        <div class="user-referals-data__titles_name">Количество начислений</div>
        <div class="user-referals-data__titles_created">Дата начисления</div>
        
        <div class="user-referals-data__titles_comment">Комментарий</div>
        <div class="user-referals-data__titles_created">Управление</div>
    </div>
    <div class="user-referals-data__list">
        <div class="user-referals-data__item" v-for="(coin, index) in coins" :key="index">
            <div class="user-referals-data__item_avatar">{{coin.title}}</div>
            <div class="user-referals-data__item_name">{{coin.amount}}</div>
            <div class="user-referals-data__item_created">{{new Date(coin.created * 1000).toLocaleString()}}</div>
            <div class="user-referals-data__item_comment" v-if="coin.comment">{{coin.comment}}</div>
            <div class="user-referals-data__item_comment" v-else>Отсутствует</div>
            <div class="user-referals-data__item_created">
                <div class="bonus-fold pointer" @click="changeCoin(false, coin.id)" v-if="coin.correct">Снять начисление</div>
                <div class="bonus-accept pointer" @click="changeCoin(true, coin.id)" v-else>Вернуть начисление</div>
            </div>
        </div>
    </div>
</div>
<Loader v-if="loader"/>
</template>

<script>
import {
    ref,
    onMounted,
    onUpdated
} from "vue"
import {
    useCoins
} from "@/composition/useCoins.js"
import {
    useRoute
} from "vue-router"
import Loader from "@/components/Loader.vue"
export default {
    components:{
        Loader
    },
    setup() {
        const {
            getCoins,
            coins,
            changeCoins,
        } = useCoins()
        const route = useRoute()
        const user_id = ref(null)
        const loader = ref(true)
        onMounted(() => {
            loader.value = true
            user_id.value = route.params.id
            getCoins(user_id.value)
            .finally(() => {
                loader.value = false
            })
        })

        const changeCoin = (value ,id) => {
            loader.value = true
            changeCoins({correct: value, comment: "Снятие администратором"}, id)
            .then(() => {
                getCoins(user_id.value)
            })
            .finally(() => {
                loader.value = false
            })
        }
        return {
            user_id,
            coins,
            changeCoin,

            loader,
        }
    }
}
</script>

<style lang="scss" src="@/styles/profile/users/userCoinsData.scss" scoped></style>
