<template>
<div class="user-stories-data__header">
    <div class="user-stories-data__titles">
        <div class="user-stories-data__titles_avatar">Аватар</div>
        <div class="user-stories-data__titles_name">Имя</div>
        <div class="user-stories-data__titles_created">Выложено</div>
        <div class="user-stories-data__titles_topic">Тема</div>
        <div class="user-stories-data__titles_views">Просмотры</div>
        <div class="user-stories-data__titles_hugs">Обнимашки</div>
        <div class="user-stories-data__titles_hashtag">Хэштеги</div>
        <div class="user-stories-data__titles_controls">Управление</div>
    </div>
    <div class="user-stories-data__list">
        <div class="user-stories-data__item" v-for="(storie, index) in stories" :key="index">
            <img class="user-stories-data__item_avatar avatar-img" @click="openStorie(storie)" v-if="storie.gallery.length == 0" src="@/assets/logo.svg" style="background: #333; padding: 5px;">
            <img class="user-stories-data__item_avatar avatar-img" @click="openStorie(storie)" :src="storie.gallery[0].main_link" v-else>
            <div class="user-stories-data__item_name" @click="openStorie(storie)">{{storie.text}}</div>
            <div class="user-stories-data__item_created" @click="openStorie(storie)">{{new Date(storie.created * 1000).toLocaleDateString()}}</div>
            <div class="user-stories-data__item_topic" @click="openStorie(storie)" v-if="storie.topic">{{storie.topic.name}}</div>
            <div class="user-stories-data__item_topic" @click="openStorie(storie)" v-else>Отсутвует</div>
            <div class="user-stories-data__item_views" @click="openStorie(storie)">{{storie.views_count}}</div>
            <div class="user-stories-data__item_hugs" @click="openStorie(storie)">{{storie.hugs_count}}</div>
            <div class="user-stories-data__item_hashtag" @click="openStorie(storie)">
                <p v-for="(hashtag, jindex) in storie.hashtags" :key="jindex"> #{{hashtag.text}}</p>
            </div>
            <div class="user-stories-data__item_controls">
                <svg @click="delet(storie.id)" class="pointer" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#FFFFFF"><path d="M0 0h24v24H0z" fill="none"/><path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"/></svg>
            </div>
        </div>
    </div>
</div>
<ModalStories v-if="modalStories" @close="closeModalStories" :storie="dataStorie"/>
<Loader v-if="loader"/>
</template>

<script>
import {
    ref,
    onMounted,
    onUpdated
} from "vue"
import {
    useStories
} from "@/composition/useStories.js"
import {
    useRoute
} from "vue-router"
import ModalStories from "@/components/Modals/ModalStories.vue"
import Loader from "@/components/Loader.vue"
export default {
    components:{
        ModalStories,
        Loader,
        
    },
    setup() {
        const {
            getStories,
            stories,
            deleteStories,
        } = useStories()
        const loader = ref(true)
        const route = useRoute()

        const delet = (id) => {
            let user_id = route.params.id
            loader.value = true
            deleteStories(id)
            .then(() => {
                getStories(user_id)
            })
            .finally(() => {
                loader.value = false
            })
        }

        const dataStorie = ref(null)

        const modalStories = ref(false)
        const opneModalStories = () => {
            modalStories.value = true
        }
        const closeModalStories = () => {
            modalStories.value = false
        }
        const openStorie = (storie) => {
            dataStorie.value = storie
            opneModalStories()
        }

        onMounted(() => {
            let user_id = route.params.id
            loader.value = true
            getStories(user_id)
            .then(() => {
                console.log(stories.value)
            })
            .finally(() => {
                loader.value = false
            })
        })
        return {
            stories,
            delet,

            openStorie,
            dataStorie,

            modalStories,
            opneModalStories,
            closeModalStories,

            loader
        }
    }
}
</script>

<style lang="scss" src="@/styles/profile/users/userStoriesData.scss"></style>
