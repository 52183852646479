<template>
<div class="user">
    <UserMainData/>
    <div class="user__navigation">
        <div class="user__navigation_link" :class="{'user__navigation_link-active':link.active }" v-for="(link, index) in links" :key="index" @click="activeLink(index)">{{link.name}}</div>
    </div>
    <UserReferalsData v-if="links[0].active" />
    <UserDevicesData v-if="links[1].active" />
    <UserStoriesData v-if="links[2].active" />
    <UserSubscripsData v-if="links[3].active" />
    <UserCoinsData v-if="links[4].active" />
</div>
</template>

<script>
import UserMainData from "@/components/User/UserMainData.vue"
import UserReferalsData from "@/components/User/UserReferalsData.vue"
import UserDevicesData from "@/components/User/UserDevicesData.vue"
import UserStoriesData from "@/components/User/UserStoriesData.vue"
import UserSubscripsData from "@/components/User/UserSubscripsData.vue"
import UserCoinsData from "@/components/User/UserCoinsData.vue"
export default {
    components: {
        UserMainData,
        UserReferalsData,
        UserDevicesData,
        UserStoriesData,
        UserSubscripsData,
        UserCoinsData,
    },

    data() {
        return {
            links: [
                {active: true, name: "Рефералы"},
                {active: false, name: "Устройства"},
                {active: false, name: "Истории"},
                {active: false, name: "Подписки/Подписчики"},
                {active: false, name: "История начислений"},
            ]
        }
    },
    methods: {
        activeLink(index){
            this.links.forEach(element => {
                element.active = false
            })
            this.links[index].active = true
        }
    },

    setup() {
        return {
            
        }
    }
}
</script>

<style lang="scss" src="@/styles/profile/users/user.scss"></style>
