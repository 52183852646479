import axios from "axios";
import { ref } from "vue";
import { useApi } from "@/composition/useApi.js";

export function useGroups() {
  const { api, params } = useApi();
  const groups = ref();
  const lessons = ref();
  const applications = ref();
  const pagination = ref();
  const getGroups = (page) => {
    return axios
      .get(api + `/api/v1/cp/groups/?page=${page}`, params)
      .then((r) => {
        groups.value = r.data.data;
        pagination.value = r.data.meta.paginator;
      });
  };
  const getApplications = (page) => {
    return axios
      .get(api + `/api/v1/cp/groups/requests/?page=${page}`, params)
      .then((r) => {
        applications.value = r.data.data;
        pagination.value = r.data.meta.paginator;
      });
  };

  const acceptApplication = (group_request_id, body) => {
    return axios.put(
      api + `/api/v1/cp/groups/requests/${group_request_id}/`,
      body,
      params
    );
  };
  const deleteApplication = (group_request_id /* body */) => {
    return axios.delete(
      api + `/api/v1/cp/groups/requests/${group_request_id}/`,
      /* body, */
      params
    );
  };

  const deleteGroups = (id) => {
    return axios.delete(api + `/api/v1/cp/groups/${id}/`, params);
  };
  const createGroup = (body) => {
    return axios.post(api + `/api/v1/cp/groups/`, body, params);
  };
  const createCover = (body, id) => {
    return axios.post(api + `/api/v1/cp/groups/${id}/cover/`, body, params);
  };

  const getLessons = (id, page) => {
    return axios
      .get(api + `/api/v1/cp/groups/${id}/lessons/?page=${page}`, params)
      .then((r) => {
        lessons.value = r.data.data;
        pagination.value = r.data.meta.paginator;
      });
  };
  const deleteLessons = (id) => {
    return axios.delete(api + `/api/v1/cp/lessons/${id}/`, params);
  };
  const createLesson = (dataLesson, id_group) => {
    return axios.post(
      api + `/api/v1/cp/groups/${id_group}/lessons/`,
      dataLesson,
      params
    );
  };

  const changeLesson = (dataLesson, id_lesson) => {
    return axios.put(
      api + `/api/v1/cp/lessons/${id_lesson}/`,
      dataLesson,
      params
    );
  };

  return {
    groups,
    getGroups,
    deleteGroups,

    lessons,
    getLessons,
    deleteLessons,
    createLesson,
    changeLesson,

    getApplications,
    applications,
    acceptApplication,

    createGroup,
    createCover,

    pagination,
    deleteApplication,
  };
}
