import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [

  {
    path: '/autorization', name: 'Autorization', redirect: { name: 'Login' }, component: () => import('../views/Autorization/Autorization.vue'), meta: { title: 'NotAlone - Авторизация' },
    children: [
      {
        path: '/autorization/login', name: 'Login', component: () => import('../views/Autorization/Login.vue'), meta: { title: 'NotAlone - Авторизация' }
      },
      {
        path: '/autorization/recover', name: 'Recover', component: () => import('../views/Autorization/Recover.vue'), meta: { title: 'NotAlone - Востановление пароля' }
      },
    ]
  },
  {
    path: '/profile', name: 'Profile', alias: '/', redirect: { name: 'Users' }, component: () => import('../views/Profile/Profile.vue'), meta: { title: 'NotAlone - Профиль', link: "Профиль" },
    children: [
      {
        path: '/profile/users', name: 'Users', redirect: { name: 'Userlist' }, component: () => import('../views/Profile/Users/Users.vue'), meta: { title: 'NotAlone - Пользователи', link: "Пользователи" },
        children: [
          {
            path: '/profile/users/userlist', name: 'Userlist', component: () => import('../views/Profile/Users/Userlist.vue'), meta: { title: 'NotAlone - Пользователи', link: "Пользователи" }
          },
          {
            path: '/profile/users/user/:id', name: 'User', component: () => import('../views/Profile/Users/User.vue'), props: true, meta: { title: 'NotAlone - Пользователь', link: "Данные пользователя" }
          },
          {
            path: '/profile/users/reports-users', name: 'Reports-users', component: () => import('../views/Profile/Users/Reports-users.vue'), meta: { title: 'NotAlone - Пользователи', link: "Жалобы на пользователей" }
          },
          {
            path: '/profile/users/reports-history', name: 'Reports-history', component: () => import('../views/Profile/Users/Reports-history.vue'), meta: { title: 'NotAlone - Пользователи', link: "Жалобы на истории" }
          },
        ]
      },
      /* {
        path: '/profile/users/user/:id', name: 'User', component: () => import('../views/Profile/Users/User.vue'), meta: { title: 'NotAlone - Пользователь', link: "Пользователь" }
      }, */
      
      {
        path: '/profile/webinars', name: 'Webinars', component: () => import('../views/Profile/Webinars/Webinars.vue'), meta: { title: 'NotAlone - Группы', link: "Вебинары" },
        children: [
         /*  {
            path: '/profile/webinars/lessons', name: 'Lessons', component: () => import('../views/Profile/Webinars/Lessons.vue'), meta: { title: 'NotAlone - Пользователи', link: "Занятия" }
          }, */
          {
            path: '/profile/webinars/groups', name: 'Groups', component: () => import('../views/Profile/Webinars/Groups.vue'), meta: { title: 'NotAlone - Группы', link: "Группы" }
          },
          {
            path: '/profile/webinars/lessons/:id', name: 'Lesons', component: () => import('../views/Profile/Webinars/Lesons.vue'), meta: { title: 'NotAlone - Группы', link: "Список тем" }
          },
        ]
      },

      {
        path: '/profile/topics', name: 'Topics', redirect: { name: 'TopicsList' }, component: () => import('../views/Profile/Topics/Topics.vue'), meta: { title: 'NotAlone - Темы', link: "Темы" },
        children: [
          {
            path: '/profile/topics/topicslist', name: 'TopicsList', component: () => import('../views/Profile/Topics/TopicsList.vue'), meta: { title: 'NotAlone - Группы', link: "Список тем" }
          },
         /*  {
            path: '/profile/topics/lessons/:id', name: 'Lesons', component: () => import('../views/Profile/Topics/Lesons.vue'), meta: { title: 'NotAlone - Группы', link: "Список тем" }
          }, */
        ]
      },

      {
        path: '/profile/dashboard', name: 'Topics', redirect: { name: 'DashboardStatistic' }, component: () => import('../views/Profile/Dashboard/Dashboard.vue'), meta: { title: 'NotAlone - Темы', link: "Темы" },
        children: [
          {
            path: '/profile/dashboard/dashboard-statistic', name: 'DashboardStatistic', component: () => import('../views/Profile/Dashboard/DashboardStatistic.vue'), meta: { title: 'NotAlone - Группы', link: "Статистика" }
          },
        ]
      },

      {
        path: '/profile/pages', name: 'Pages', redirect: { name: 'PagesList' }, component: () => import('../views/Profile/Pages/Pages.vue'), meta: { title: 'NotAlone - Страницы', link: "Страницы" },
        children: [
          {
            path: '/profile/pages/pageslist', name: 'PagesList', component: () => import('../views/Profile/Pages/PagesList.vue'), meta: { title: 'NotAlone - Страницы', link: "Страницы" }
          },
          {
            path: '/profile/pages/page/:id', name: 'Page', component: () => import('../views/Profile/Pages/Page.vue'), meta: { title: 'NotAlone - Страницы', link: "Страница" }
          },
        ]
      },

      {
        path: '/profile/applications', name: 'applications',  component: () => import('../views/Profile/ApplicationsGroups/ApplicationsGroups.vue'), meta: { title: 'NotAlone - Заявки', link: "Заявки на создание группы" },
        /* children: [
          {
            path: '/profile/documents/documentsList', name: 'DocumentsList', component: () => import('../views/Profile/Documents/DocumentsList.vue'), meta: { title: 'NotAlone - Документы', link: "Список документов" }
          },
          {
            path: '/profile/documents/document/:id', name: 'Документ', component: () => import('../views/Profile/Documents/Document.vue'), meta: { title: 'NotAlone - Документы', link: "Список документов" }
          },
        ] */
      },

      /* {
        path: '/profile/documents', name: 'Documents', redirect: { name: 'DocumentsList' }, component: () => import('../views/Profile/Documents/Documents.vue'), meta: { title: 'NotAlone - Документы', link: "Документы" },
        children: [
          {
            path: '/profile/documents/documentsList', name: 'DocumentsList', component: () => import('../views/Profile/Documents/DocumentsList.vue'), meta: { title: 'NotAlone - Документы', link: "Список документов" }
          },
          {
            path: '/profile/documents/document/:id', name: 'Документ', component: () => import('../views/Profile/Documents/Document.vue'), meta: { title: 'NotAlone - Документы', link: "Список документов" }
          },
        ]
      }, */
    ]
  },


/*   {
    path: '/autorization/login',
    name: 'Login',
    component: Login
  }, */
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
